import React, { Component, useRef , useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import Pagefooter from './footer';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class Forgotpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectSignin:false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,
      
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,

      redirectHome:false,
      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      EmailErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      resetData: {
        email_id: '',
        login_type:'',
        // password: '',
        // role: '',
        // loginType: 'team',
      },

      
     
      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
   
  }

  
 

  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal()
  {
    
      this.setState({redirectSignup:true})
    

  }


  redirectSignin()
  {
    this.setState({redirectSignin:true})
  }

  validate = () => {
    let EmailErr = '';
    
    if (!this.state.resetData.email_id) {
      EmailErr = 'Enter registerd email id';
    }
    
    if (EmailErr  ) {
      this.setState({EmailErr });
      return false;
    }
    return true;
  }

  
  resetfun() {
    
    const isValid = this.validate();
    if (isValid) 
    {      
      
      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl+'/service/KOL_landing/password_reset';
      let formData = new FormData();
      formData.append('Reset_Info',JSON.stringify(this.state.resetData));
      formData.append('Current_url',global.websiteUrl);
     
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }
      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data.User_List;
          console.log("lgdata===========", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
          this.setState({
            ShowBar: false,
          })
          if (lgstatus == true) {
            Swal.fire({
              icon: 'success',
              text:'Password reset link has been sent to your registerd email id',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              redirectHome:true,
            })
          }
          else if (lgstatus == false) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Fill correct user id',
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({ShowBar:false,})

          }
        })
        .catch(error => {
          console.log(error);
        });
       
    }
  }



  // resetfun() {
    
  //   const isValid = this.validate();
  //   if (isValid) 
  //   {      
  //     this.setState({
  //       ShowBar: true,
  //     })

   
  //   const clid=this.state.resetData.client_id;    
  //   var cutchar = (clid.substring(0,5)).toUpperCase();

  //   if(cutchar =="CHCEM")
  //   {
  //     let { resetData } = this.state;
  //       resetData.login_type = '';
     
  //       resetData.login_type = 'Employee';
  //       this.setState({ resetData });
        
  //   }
  //   else if(cutchar =="CHCCI")
  //   {
        
  //       let { resetData } = this.state;
  //       resetData.login_type = '';     
  //       resetData.login_type = 'Client';
  //       this.setState({ resetData });
      
  //   }
   
  //   //console.log("this.state.resetData.visitor_id", this.state.loginData.client_id)
  //     const url = global.websiteUrl+'/service/KOL_landing/Forgot_Password';
  //     let formData = new FormData();
  //     formData.append('Reset_Info',JSON.stringify(this.state.resetData));
     
  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }
  //     axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.data.User_List;
  //         console.log("lgdata===========", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("lgstatus", JSON.stringify(lgstatus));
  //         console.log("response message2", response.data.message)
  //         this.setState({
  //           ShowBar: false,
  //         })
  //         if (lgstatus == true) {
  //           Swal.fire({
  //             icon: 'success',
  //             text:'Password reset successfull',
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         else if (lgstatus == false) {
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Oops...',
  //             text: 'Fill correct user id',
  //             // showCancelButton: true,
  //             confirmButtonColor: '#d33',
  //             cancelButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })

  //           this.setState({ShowBar:false,})

  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
       
  //   }
  // }

  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
      


    });

  }

  makespaceondivkdprofiling()
  {
    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }

  
  render() {

   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }

  
  if (this.state.redirectHome) {
    return (<Redirect to={'/Home'}/>)
}


    if (this.state.redirectSignin) {
        return (<Redirect to={'/user login'}/>)
    }

    if (this.state.redirectAdmin) {
      return (<Redirect to={'/AdminDashboard'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }


    return (

        
      <body id="landing-body vh-100" style={{ backgroundColor:'#ccc'}}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  


<div style={{ backgroundColor:'#e9ecef', marginTop:'0px', paddingTop:'40px'}}>        
       
     <LoginHeader/>

    <section id="services" className="text-center" >
     
        <div className="container" >
            <div className="row">
                <div className="col-12">
                    <div className="login" style={{padding:'2%'}}>
                                  <div>
                                    <h4>Forgot Password? !</h4>
                                    <p>Reset password with Cetas Healthcare</p>
                                    <h6>Enter your registerd email id and instructions will be sent to you!</h6>
                                  </div>
                                    <div className='inputbox'>
                                        <label for="" >Email ID</label>
                                        <Input id="email_id" placeholder="Enter Registerd Email id" tabindex='1' class="input"
                                        type="email"
                                        value={this.state.resetData.email_id} onChange={(e) => {
                                          let { resetData } = this.state;
                                          resetData.email_id = e.target.value;
                                          this.setState({ resetData });
                                          // this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                                        }}
                                      />                                     
                                    </div>
                                    <div className="error"> {this.state.EmailErr}</div>


                                  
                                    <div >
                                       <button type="submit" tabindex='3' onClick={this.resetfun.bind(this)}>
                                        Send Reset Link
                                      </button>
                                  </div>
                                  {/* <h5>Sign In with</h5>    
                                  <div className='inputboxsocial'>
                                   <a href="#" target="_blank"><i className='bx bxl-facebook'></i></a>
                                   <a href="#" target="_blank"><i className='bx bxl-google'></i></a>
                                   <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                                  
                                   <a href="#" target="_blank"><i className='bx bxl-twitter'></i></a>
                  
                                  </div> */}
                                {/* </form>
                            </div> */}                   
                    </div>
                </div>
            </div>            
            <div className="signuplink">
                <p >Wait, I remember my password... <a href='#' onClick={this.redirectSignin.bind(this)}>Click here</a></p>
            </div>
        </div>
    </section>


    <Pagefooter/> 

</div>

</body>
    );
  }
}

export default Forgotpass;