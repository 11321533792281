import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
//import jsPDF from 'jspdf';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
//import html2pdf from 'html2pdf.js';

//import html2pdf from 'html2pdf.bundle.min.js';
import html2pdf from 'html2pdf.js';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { NavLink } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
import ApexChart from './ApexChart';
import Swal from 'sweetalert2';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import { PropagateLoader } from 'react-spinners';

import '../styles/profile.scss';

import ClientHeader from './ClientHeader';
import { keyCodes } from 'reactstrap/lib/utils';
import jsPDF from 'jspdf';
var jwt=require('jsonwebtoken');
var jwtkey= require('../config');
// or
const html2PDF = require('html2canvas');



class ClientViewProfile extends Component {

  constructor(props) {
    const profile_image_path_for_pdf = "";

    super(props);
    var IMGNAME = "";
    // var curr_designation="zz";
    // var curr_department="xy";


    this.state = {


      // Profile Page tables
      // Work Detail Columns
      workDetailsColumn: [
        {
          dataField: 'current_place_of_work',
          text: 'Place of Work',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'designation',
          text: 'Designation',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        }
      ],
      //Education Column
      educationColumn: [
        {
          dataField: 'institute_name',
          text: 'Alma Mater',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'institute_type',
          text: 'Alma Mater Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'degrees',
          text: 'Qualification',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // speciality_id
        {
          dataField: 'speciality_id',
          text: 'Speciality',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // formatter: (cell, row) => cell != undefined ? cell : "NA",
        {
          dataField: 'years_attended_from',
          text: 'Tenure',
          formatter: (cell, row) => `${row.years_attended_from.replace('----', "")} - ${row.years_attended_to.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        }
      ],
      // Professional Membership
      professionalMemColumn: [
        {
          dataField: 'organization',
          text: 'Organization Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'organization_type',
          text: 'Organization Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'tenure',
          text: 'Tenure',
          formatter: (cell, row) => `${row.tenure.replace('----', "")} - ${row.tenure1.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        }
      ],
      //Press
      pressColumn: [
        {
          dataField: 'title',
          text: 'Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // {
        //   dataField: 'quote',
        //   text: 'Quote',
        //   headerStyle: (colum, colIndex) => {
        //     return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
        //   },
        // },
        {
          dataField: 'source_link',
          text: 'Press Item Links',
          formatter: (cell, row) => {
            return (
              <div>
                <a style={{ color: '#069', textDecoration: 'underline', cursor: 'pointer', fontSize: '12px', fontWeight: 'normal' }}
                  href={cell}> Press Item </a>
              </div>
            )
            // `${< a href={cell} target='_blank' > "Press Item"</a >}`
          },
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        }
      ],
      //Advisory Board
      advisoryBoardColumn: [
        {
          dataField: 'year',
          text: 'Tenure',
          formatter: (cell, row) => `${row.year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'company',
          text: 'Company',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'type_of_company',
          text: 'Company Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'advisory_board',
          text: 'Advisory Board',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // {
        //   dataField: 'topic',
        //   text: 'Topic',
        //   headerStyle: (colum, colIndex) => {
        //     return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
        //   },
        // },
        {
          dataField: 'role_id',
          text: 'Role',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Awards & Honours
      awardsHonoursColumn: [
        {
          dataField: 'awards_honours',
          text: 'Award Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'Awarding_Organisation',
          text: 'Awarding Organization',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // {
        //   dataField: 'types',
        //   text: 'Types of Awards & Honours',
        //   headerStyle: (colum, colIndex) => {
        //     return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
        //   },
        // },
        {
          dataField: 'year',
          text: 'Year',
          formatter: (cell, row) => `${row.year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Commitees
      commiteesColumn: [
        {
          dataField: 'year',
          text: 'Tenure',
          formatter: (cell, row) => `${row.year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'body_org_name',
          text: 'Body / Org Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'committee',
          text: 'Committee',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      //Books & Monographs
      booksMonographColumn: [
        {
          dataField: 'year',
          text: 'Year',
          // formatter: (cell, row) => `${row.year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'publication',
          text: 'Publication',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'editors',
          text: 'Editors',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Events Detail
      EventsDetailsColumn: [
        {
          dataField: 'events',
          text: 'Event Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'event_type',
          text: 'Event Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'event_topic',
          text: 'Event Topic',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'session_type',
          text: 'Session Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // / Grants
      GrantsColumn: [
        {
          dataField: 'company',
          text: 'Company/Organization',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'year',
          text: 'Tenure',
          formatter: (cell, row) => `${row.year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Guidelines
      guidelinesColumn: [
        {
          dataField: 'year',
          text: 'Year',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'organisation',
          text: 'Organization',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'type_of_organization',
          text: 'Type of Organization',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'role',
          text: 'Role',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Hospital Affiliation
      hospitalAffiliationColumn: [
        {
          dataField: 'hospital',
          text: 'Hospital',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'role',
          text: 'Role',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'year',
          text: 'Tenure',
          formatter: (cell, row) => `${row.from_year.replace('----', "")} - ${row.to_year.replace('----', "")}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        }, {
          dataField: 'city_id',
          text: 'Location',
          formatter: (cell, row) => `${row.city_id}, ${row.state_id}, ${row.country_id}`,
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Presentations
      presentationsColumn: [
        {
          dataField: 'event_name',
          text: 'Events/Conference Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Publication
      publicationColumn: [
        {
          dataField: 'journal',
          text: 'Journal',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'publication_title',
          text: 'Publication Title',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        // PMID
        {
          dataField: 'PMID',
          text: 'PMID',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      // Sunshine Payment
      sunshinePaymentColumn: [
        {
          dataField: 'date',
          text: 'Date',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'payment_type',
          text: 'Payment Type',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
        {
          dataField: 'OL_address',
          text: 'Company Name',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff' };
          },
        },
      ],
      curr_designation: '',
      curr_department: '',
      iname: "pie2.jpg",
      profile_image_Name_for_pdf: "default-user.jpg",


      // for image
      file: '',
      imagePreviewUrl: '',
      secondryEmail: '',

      subspecilityfinal: '',
      procedurefinal: '',

      searchInCetas: false,
      redirect: false,
      kol_id: '',
      project_id: '',
      completeAddress: '',
      client_token:'',

      redirectBackToKolList: false,
      redirectBackToDashboard: false,

      redirectSearchInCetasDb: false,
      RedirectLogin: false,

      WorkdetailsFlag: false,
      PersonalInformationFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      // ContractFlag: false,
      //ProcedurePotentialFlag: false,
      BiographyFlag: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      profiledisplayFlag: false,
      exportbuttonFlag: false,


      Profile: [],
      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],

      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',

      CountryListData: [],
      StateData: [],
      CityData: [],
      EmployeeListData: [],

      // KolRatingScore data
      event_kol_rating: [],
      publication_kol_rating: [],
      ad_board_kol_rating: [],
      guideline_kol_rating: [],
      clinicaltrial_kol_rating: [],

      //piechart data
      event_piechart: [],
      ad_board_piechart: [],
      publication_piechart: [],
      guideline_piechart: [],
      clinicaltrial_piechart: [],



      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'project_id',
          text: 'Project ID',
          sort: true,
        }
        ,

      ],
    }
  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('client_id')
    sessionStorage.removeItem('client_name')
    sessionStorage.removeItem('email_id')
  }

  getPhoto = event => {
    event.preventDefault();
    console.log("photo event", event.target.files[0])

    if (event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];
      // this.setState({file: e.target.files[0],})
      console.log("file my", this.state.profile_photo);
      reader.onloadend = () => {
        this.setState({
          profile_photo: file,
          imagePreviewUrl: reader.result,
          profile_photo_for_edit: '',
        });
      }
      reader.readAsDataURL(file);
    }
  }

  componentDidMount = async () => {

    let getSession_wtoken = await sessionStorage.getItem('wtoken')
    
    jwt.verify(getSession_wtoken, jwtkey.jwt.key, async (err, response) => {
      if (!err && (response.login_type =="client" || response.login_type =="client_demo"))
        {
        let getSession_Client_token = await sessionStorage.getItem('token')
        if (getSession_Client_token !== null) {
          await this.setState({
            client_token: getSession_Client_token,
          })
        }        
        let getSession_Kol_Id = sessionStorage.getItem('kol_id')
        if (getSession_Kol_Id !== null) {
          this.setState({
            kol_id: JSON.parse(getSession_Kol_Id),
          })
        }
        else {
          this.setState({ redirect: true });         
          
        }

        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
          await this.setState({
            project_id: getSession_Project_Id,
          })
        }
        
        this.setState({
          ShowBar: true,
          progressBar: true,
        })

        const options = {
          method: 'POST',
        }


        
        const url = global.websiteUrl + '/service/Client/get_KOL_details_forprofile';
        let formData = new FormData();
        formData.append('kol_id', this.state.kol_id);
       // formData.append('project_id', this.state.project_id);
        formData.append('token_id',this.state.client_token);

        const config = {
          //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
          .then(response => {
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));

            var KOL_Personal_Info = response.data.KOL_Personal_Info;
            console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));


            var KOL_Work_Details = response.data.KOL_Work_Details;
            console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
            // var Inter = response.data.KOL_Interactions;
            // console.log("KOL_Interactions==========", JSON.stringify(Inter));
            this.setState({
              PersonalInformation: KOL_Personal_Info,
              // WorkDetails: response.data.KOL_Work_Details,
              WorkDetails: KOL_Work_Details,

              KOl_Identifier: response.data.KOL_Identifiers,
              Biography: response.data.KOL_Biography,
              ProfessionalMemberships: response.data.KOL_Professional_Memberships,
              Education: response.data.KOL_Education,
              AdvisoryBoard: response.data.KOL_Advisory_Board,
              AwardsHonours: response.data.KOL_Awards_Honours,
              BooksMonographs: response.data.KOL_Books_Monographs,
              Commitees: response.data.KOL_Commitees,
              ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
              EventsDetails: response.data.KOL_Events_Details,
              Grants: response.data.KOL_Grants,
              Guidelines: response.data.KOL_Guidelines,
              HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
              Interactions: response.data.KOL_Interactions,
              PressItem: response.data.KOL_Press_Item,
              Presentation: response.data.KOL_Presentations,
              PublicationDetails: response.data.KOL_Publication_Details,
              SunshinePayment: response.data.KOL_Sunshine_Payment,

              profile_photo_for_edit: response.data.KOL_Profile_Photo,
              // profile_image_for_pdf:response.data.KOL_Profile_Photo,

              // BiographyResume_edit: response.data.KOL_Resume,
              // BiographyResumeFileName: response.data.KOL_Resume,

              profile_photo: response.data.KOL_Profile_Photo,
              // BiographyResume: response.data.KOL_Resume, 

            })

            this.setState({
              ShowBar: false,
              profiledisplayFlag: true,
              exportbuttonFlag: true,
              // ContractFileName: ContractFileNameapi
            })

            
            console.log("PersonalInformation", this.state.PersonalInformation);
            console.log("profile_photo_for_edit", this.state.profile_photo_for_edit);
            console.log("Education", this.state.Education);
            //console.log("WorkDetails===============", this.state.WorkDetails);
            console.log("WorkDetails===============", this.state.WorkDetails);

            /// Split and Set Image Name
            if (this.state.profile_photo !== null && this.state.profile_photo !== '') {

              var pathsplit = this.state.profile_photo.split('/');
              if (pathsplit.length > 4) {
                if (pathsplit[2] === "cetas.medtech-gurus.com") {
                  //this.state.IMGNAME=pathsplit[5];
                  this.setState({
                    profile_image_Name_for_pdf: pathsplit[5],
                  })

                }
                else if (pathsplit[2] === "app.medtech-gurus.com") {
                  // this.state.IMGNAME=pathsplit[4];
                  this.setState({
                    profile_image_Name_for_pdf: pathsplit[4],
                  })

                }

              }

            }



            if (this.state.PersonalInformation.doctor_email2 !== "") {
              this.setState({

                secondryEmail: ", " + this.state.PersonalInformation.doctor_email2,
              })

            }

            if (this.state.PersonalInformation.sub_specialty_id !== "NA") {
              this.setState({
                subspecilityfinal: this.state.PersonalInformation.sub_specialty_id,
              })

            }

            if (this.state.PersonalInformation.procedure_type_id !== "NA") {
              this.setState({

                procedurefinal: this.state.PersonalInformation.procedure_type_id,
              })

            }


            if (this.state.WorkDetails[0].current_place_of_work !== "") {
              this.state.curr_designation = this.state.WorkDetails[0].designation;
              this.state.curr_department = this.state.WorkDetails[0].department;
              this.state.WorkdetailsFlag = true;
            }

            //alert(this.state.ProfessionalMemberships[0].organization);
            if (this.state.ProfessionalMemberships[0].organization !== "") {

              this.setState({
                ProfessionalMembershipFlag: true,
              })

            }

            if (this.state.Education[0].degrees !== "") {
              //this.state.EducationFlag=true;   
              this.setState({
                EducationFlag: true,
              })

            }

            if(this.state.AdvisoryBoard !=false)
            {
              if (this.state.AdvisoryBoard[0].company !== "") {
                this.setState({
                  AdvisoryBoardFlag: true,
                })

                // this.state.AdvisoryBoardFlag=true;     
              }
          }

            if (this.state.AwardsHonours[0].awards_honours !== "") {
              this.setState({
                AwardsAndHonoursFlag: true,
              })
              //this.state.AwardsAndHonoursFlag=true;     
            }

            if (this.state.BooksMonographs[0].publication !== "") {
              this.setState({
                BooksAndMonographFlag: true,
              })
              //this.state.BooksAndMonographFlag=true;     
            }

            if(this.state.Commitees !=false)
              {
             
              if (this.state.Commitees[0].role_id !== "") {
                this.setState({
                  CommiteesFlag: true,
                })
                // this.state.CommiteesFlag=true;     
              }
            }

            if(this.state.ClinicalTrialDetails !=false)
            {
            if (this.state.ClinicalTrialDetails[0].study_details !== "") {
              this.setState({
                ClinicalTrialDetailsFlag: true,
              })
              // this.state.ClinicalTrialDetailsFlag=true;     
            }
          }

            if (this.state.EventsDetails[0].event_topic !== "") {
              this.setState({
                EventsDetailsFlag: true,
              })
              //  this.state.EventsDetailsFlag=true;     
            }
            if (this.state.Grants[0].company !== "") {
              this.setState({
                GrantsFlag: true,
              })
              // this.state.GrantsFlag=true;     
            }
            if (this.state.Guidelines[0].organization !== "") {
              this.setState({
                GuidelinesFlag: true,
              })
              //this.state.GuidelinesFlag=true;     
            }


            if (this.state.HospitalAffiliations[0].hospital !== "") {
              this.setState({
                HospitalAffiliationsFlag: true,
              })
              // this.state.HospitalAffiliationsFlag=true;     
            }

            this.setState({
              InteractionsFlag: true,
            })

            if (this.state.PressItem[0].title !== "") {
              this.setState({
                PressItemFlag: true,
              })
              //this.state.PressItemFlag=true;     
            }

            if (this.state.Presentation[0].event_name !== "") {
              this.setState({
                PresentationsFlag: true,
              })
              // this.state.PresentationsFlag=true;     
            }
            if (this.state.PublicationDetails[0].journal !== "") {
              this.setState({
                PublicationDetailsFlag: true,
              })
              //this.state.PublicationDetailsFlag=true;     
            }

            if (this.state.SunshinePayment[0].payment_type !== "") {
              this.setState({
                SunshinePaymentFlag: true,
              })
              // this.state.SunshinePaymentFlag=true;     
            }

            if (this.state.PersonalInformation.address_1 !== "") {
              this.setState({
                completeAddress: this.state.PersonalInformation.address_1,

              })

            }


            if (this.state.PersonalInformation.address_2 !== "") {
              this.setState({
                completeAddress: this.state.completeAddress + ', ' + this.state.PersonalInformation.address_2,

              })

            }
            if (this.state.PersonalInformation.city !== "") {
              this.setState({
                completeAddress: this.state.completeAddress + ', ' + this.state.PersonalInformation.city,

              })

            }


          })

          .catch(error => {
            console.log(error);
            this.setState({
              ShowBar: false,
              progressBar: false,
            });
          });

        }
        else {
          
          Swal.fire({
            icon: 'error',
            title: jwtkey.jwt.session_expired_msg_title,
            text: jwtkey.jwt.wrong_url_msg,
            // showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
          
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('wtoken');
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('email');
          sessionStorage.removeItem('login_type');
          
          sessionStorage.removeItem("speciality_id");
          this.setState({ redirect: true })
        }
  })
  }

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    if (phoneNumberLength == 10) {
      return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
    else if (phoneNumberLength == 11) {
      return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
        1,
        4
      )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    }
    else if (phoneNumberLength == 12) {
      return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        5
      )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

    }
    else {

      return + phoneNumber;
    }

  }

  // rediect funcs
  BackToDashboard() {
    this.setState({ redirectBackToDashboard: true })
  }

  BackToKolList() {
    this.setState({ redirectBackToKolList: true })
  }

  renderkolPreview() {
    let path = "https://app.medtech-gurus.com/upload_files/Picture13.jpg";
    // alert(this.state.profile_photo);

    if (this.state.profile_photo !== null && this.state.profile_photo !== '') {

      var pathsplit = this.state.profile_photo.split('/');
      //  let pathfinal ='https://mobilesutra.com/Cetas/upload_files/James_Hadfield_Photo.png';

      var image = '';
      //var IMGNAME="";
      if (pathsplit.length > 4) {
        if (pathsplit[2] === "cetas.medtech-gurus.com") {
          this.state.IMGNAME = pathsplit[5];
        }
        else if (pathsplit[2] === "app.medtech-gurus.com") {
          this.state.IMGNAME = pathsplit[4];
        }
      }


      if (this.state.profile_photo_for_edit) {
        console.log(this.state.profile_photo)
        console.log(this.state.profile_photo_for_edit)
        console.log(window.location.href)
        const host = window.location.origin
        , imagePath = `${global.websiteUrl}/ProfileImage/`
        
        //  , imagePath = `${host}/Cetas/ProfileImage/`
          //, imagePath = `${host}/ProfileImage/`
          
        return (
          <img src={imagePath + this.state.profile_photo} className="Profile-Logos" alt={this.state.PersonalInformation.doctor_full_name} />
          // <img src={require('../Assets/ProfileImage/testimage.jpg')} className="Profile-Logos" alt={this.state.PersonalInformation.doctor_full_name} />
        );
      } else {
        return (
          <img src={require('../Assets/Images/default-user.jpg')} className="Profile-Logos" alt={this.state.PersonalInformation.doctor_full_name} />
        );
      }
    }
    else {
      return (
        <img src={require('../Assets/Images/default-user.jpg')} className="Profile-Logos" alt={this.state.PersonalInformation.doctor_full_name} />
      );
    }

  }

  printpdf = () => {
    var imgWidth = 120;
    var position = 0;
    var pageheight = 0;
    //Pdf Model With seprate tab *****************

    const input = document.getElementById('printinpdf');
    html2canvas(input)
      .then((canvas) => {
        pageheight = canvas.height;
        if (pageheight <= 46917) {
          const input1 = document.getElementById('printinpdf');
          html2canvas(input1)
            .then((canvas) => {
              var imgHeight = canvas.height * imgWidth / canvas.width;
              var doc = new JsPDF('p', 'mm', [imgWidth, imgHeight]);
              var pageData = canvas.toDataURL('image/PNG', 0.5);
              var imgData = encodeURIComponent(pageData);

              const pdfWidth = doc.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
              doc.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
              doc.save('Profile_' + this.state.PersonalInformation.doctor_full_name + '.pdf');
            });
        }
        else {
//          alert("page height long")
          // General Information 

          const input_general = document.getElementById('table_basic');
          html2canvas(input_general)
            .then((canvas) => {
              //46917 
              //var imgWidth = 100;          
              //alert(canvas.height);       
              //var imgWidth = 160;
              var imgHeight = canvas.height * imgWidth / canvas.width;
              var doc = new JsPDF('p', 'mm', [imgWidth, imgHeight]);
              //        var position = 0;
              var pageData = canvas.toDataURL('image/PNG', 2);
              var imgData = encodeURIComponent(pageData);

              const pdfWidth = doc.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
              doc.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
              doc.save(this.state.PersonalInformation.doctor_full_name + '.pdf');
            });


          // // Publication Print
          if (pageheight <= 46917) {
            const input_publication = document.getElementById('table_publication');
            html2canvas(input_publication)
              .then((canvas) => {
                //46917 
                //var imgWidth = 100;          
                //alert(canvas.height);       
                //var imgWidth = 160;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var doc = new JsPDF('p', 'mm', [imgWidth, imgHeight]);
                //        var position = 0;
                var pageData = canvas.toDataURL('image/PNG', 2);
                var imgData = encodeURIComponent(pageData);

                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
                doc.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                doc.save(this.state.PersonalInformation.doctor_full_name + '_publication.pdf');
              });
          }
          else {
  //          alert("Publication record is to large");
          }
          /// Shunshine Print
          if (pageheight <= 46917) {
            const input_shunshine = document.getElementById('table_shunshine');
            html2canvas(input_shunshine)
              .then((canvas) => {
                //46917 
                //var imgWidth = 100;          
                //alert(canvas.height);       
                //var imgWidth = 160;
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var doc = new JsPDF('p', 'mm', [imgWidth, imgHeight]);
                //        var position = 0;
                var pageData = canvas.toDataURL('image/PNG', 2);
                var imgData = encodeURIComponent(pageData);

                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
                doc.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                doc.save(this.state.PersonalInformation.doctor_full_name + '_shunshine.pdf');
              });
          }
          else {
    //        alert("Shunshine record is to large ");
          }
        }
      });


  }

  render() {
    if (this.state.redirectBackToKolList) {
      return (<Redirect to={{
        pathname: '/My Database',
      }} />)
    }

    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    if (this.state.redirectBackToDashboard) {

      return (<Redirect to={{
        pathname: '/My Dashboard',
      }} />)
    }



    return (
      <body>

        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div >

          <div>
            <div style={{ marginTop: '0px', backgroundColor: 'white' }}>
              <div id="myMm" style={{ height: "1mm" }} />

              <ClientHeader ClientName={this.state.client_name} />
              <div class="container" >
                <div class="content-wrapper_not">
                  <div class="biography-backoptiopn">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3" >
                   
                    <p > {sessionStorage.getItem('login_type') == 'client_demo' ?
                      <div> 
                      </div> : <>
                        <a href="#" onClick={this.BackToDashboard.bind(this)}>
                          Dashboard
                        </a>{" "}
                        <b><span class="btn-label"><i class="fa fa-caret-right"></i></span></b>
                      </>
                    } <a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile</p>
                 
                      {/* <p ><a href='#' onClick={this.BackToDashboard.bind(this)} >Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b><a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile</p> */}
                    </div>
                  </div>

                     {(this.state.exportbuttonFlag === true) ?
                (
                  <div style={{ position: "fixed", zIndex: 1, right: "10%", top: "26%" }}>
                    <button className="btn btn-primary" onClick={this.printpdf.bind(this)}>Export to PDF!</button>
                  </div>
                )
                :
                (<div></div>)
              }
                  <div class="row content" >
                    <div >
                      <div>
                      <div class="screen-headingbox"> 
                          <h2>Profile</h2>
                            {/* {(this.state.exportbuttonFlag === true) ?
                              (
                                <button className="btn btn-primary" onClick={this.printpdf.bind(this)} >Export to PDF!</button>
                              )
                              :
                              ('')
                            }                           */}
                        </div>
                      </div>
                      <br />
                      <div className="container" id="printinpdf"  >
                        {
                          (this.state.profiledisplayFlag === true) ?
                            (
                              <div className="main-content">
                                <div className="card">
                                  <div className="card-body1">

                                    <div className="cpLogo" >
                                      <div ID="table_basic" className="shunshine_publication_background">

                                        <div className="row" style={{ height: 150 }}>
                                          <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="lelogo">
                                              {/* <div className="container-fluid arclogo"> */}
                                              <div className="container">
                                                <img src={require('../Assets/Images/Medtech_Gurus_logo.png')} width="100" className="Profile-logo" alt="logo" />
                                              </div>
                                            </div>
                                          </div>


                                          <div className="col-lg-8 col-md-8 col-sm-8">
                                            {/* <div className="card arclogo1"> */}
                                            <div className="card1 riglogo1" style={{ border: 'none' }}>
                                              <h3 className="text-right mt-1" style={{ color: 'white' }}><b>{this.state.PersonalInformation.salutation} {this.state.PersonalInformation.doctor_full_name}, {this.state.PersonalInformation.qualification}</b></h3>
                                              <h4 className="" style={{ color: 'white' }} >{this.state.curr_designation}, {this.state.curr_department}</h4>

                                              <pp>Phone:{this.formatPhoneNumber(this.state.PersonalInformation.doctor_contact_no)}, Fax:{this.formatPhoneNumber(this.state.PersonalInformation.fax)} </pp>
                                              <pp>Email: <a href="#">{this.state.PersonalInformation.doctor_email}{this.state.secondryEmail}</a></pp>
                                              {/* <p className="text-mute1" >{this.state.PersonalInformation.address_1},{this.state.PersonalInformation.state_id},{this.state.PersonalInformation.country_id}-{this.state.PersonalInformation.zip_code}</p> */}
                                              <pp>{this.state.completeAddress}, {this.state.PersonalInformation.country_id}-{this.state.PersonalInformation.zip_code}</pp>
                                              {/* <p className="" >{this.state.completeAddress}</p>
                  <p className="" >{this.state.PersonalInformation.country_id}-{this.state.PersonalInformation.zip_code}</p>
                  */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3 cDetails" >
                                          <div className="col-lg-12 col-md-12 col-sm-12 prf_lg">
                                            <div className="row">
                                              <div className="card">
                                                <div className="card-body1">
                                                  <div className="col-lg-12 col-md-12 col-sm-12 bDetails"   >
                                                    {/* <h5 ><b>Overall Rating :  {this.state.PersonalInformation.aggregate_score} </b></h5> */}
                                                    <p class="txt">Experience    : {this.state.PersonalInformation.experience}</p>
                                                    <p class="txt">Languages Known: {this.state.Biography.language_spoken}</p>
                                                    <p class="txt">Speciality    : {this.state.PersonalInformation.specialty_id}</p>
                                                    <p >Sub-Speciality: {this.state.subspecilityfinal}</p>
                                                    <p >Procedure Type: {this.state.procedurefinal}</p>
                                                    <p >Website       : <Link href='https://www.medtech-gurus.com/' target='_blank'>{this.state.PersonalInformation.website}</Link> </p>
                                                    <p >LinkedIn      : <a href="https://www.linkedin.com/in/george-church-2b86301/" target='_blank'>{this.state.PersonalInformation.linked_in_profile}</a></p>
                                                    <p >Twitter       : <a href="https://twitter.com/geochurch" target='_blank'>{this.state.PersonalInformation.twitter_handle}</a></p>
                                                    {/* <imag><img src={this.state.profile_image_path_for_pdf}  className="Profile-Logos" alt={this.state.profile_photo_for_edit} /></imag> */}
                                                    <imag>{this.renderkolPreview()}</imag>
                                                    {/* <imag><img src={require('../Cetas/upload_files/pie2.jpg')}  className="Profile-Logos" alt="Dr. George McDonald Church" /></imag> */}
                                                    {/* <imag><img src={require('../Assets/ProfileImage/'+ this.state.iname )}  className="Profile-Logos" alt="Dr. George McDonald Church" /></imag> */}
                                                    {/* <imag><img src={require('../final_profile_images/'+ this.state.profile_image_Name_for_pdf )}  className="Profile-Logos" alt="Dr. George McDonald Church" /></imag> */}
                                                    {/* <imag><img src={require(process.env.PUBLIC_URL+"../Cetas/upload_files/"+ this.state.profile_image_Name_for_pdf )}  className="Profile-Logos" alt="Dr. George McDonald Church" /></imag> */}
                                                    {/* iname */}
                                                    {/* <img src={this.state.profile_photo_for_edit}  className="Profile-Logos" alt="Dr. George McDonald Church" /> */}
                                                    {/* {this.renderkolPreview()} */}
                                                    {/* <img src={require('../Assets/Images/default-user.jpg')}className="Profile-Logos" alt={this.state.PersonalInformation.doctor_full_name}/> */}

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {(this.state.WorkdetailsFlag === true) ? (
                                          <div >
                                            <h2 className="headings mt-4" >Work Details:</h2>
                                            <div className="container-fluid">

                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails"  >
                                                    {/* this.state.workDetailsColumn */}

                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='current_place_of_work'
                                                      data={this.state.WorkDetails} columns={this.state.workDetailsColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />

                                                    
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div></div>)
                                        }
                                        {/* {(this.state.EducationFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Education:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='institute_name'
                                                      data={this.state.Education} columns={this.state.educationColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                        {
                                          //alert(this.state.ProfessionalMembershipFlag)
                                          (this.state.ProfessionalMembershipFlag === true) ? (
                                            <div>
                                              <h2 className="headings mt-4">Professional Membership:</h2>
                                              <div className="container-fluid">
                                                <div className="row mt-1">
                                                  <div className="col-12 mt-2 bcolor">
                                                    <div className="card_not nDetails">
                                                      <BootstrapTable
                                                        noDataIndication="Table is Empty"
                                                        keyField='organization'
                                                        data={this.state.ProfessionalMemberships} columns={this.state.professionalMemColumn}
                                                        rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                        hover
                                                        condensed
                                                        borderless
                                                      />

                                                      {/* {this.state.ProfessionalMemberships.map((ProfessionalMemberships, index) => (
                                                        <div className="card-body3" data-index={index}>
                                                          <div className="row mt-3 h-20">
                                                            <div className="col-2">
                                                              <p>Organization Name</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> :  </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{ProfessionalMemberships.organization}</p>
                                                            </div>
                                                          </div>

                                                          <div className="row h-20" >
                                                            <div className="col-2">
                                                              <p>Organization Type</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> : </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{ProfessionalMemberships.organization_type}</p>
                                                            </div>
                                                          </div>

                                                          <div className="row h-20" >
                                                            <div className="col-2">
                                                              <p>Department</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> : </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{ProfessionalMemberships.department}</p>
                                                            </div>
                                                          </div>


                                                          <div className="row h-20" >
                                                            <div className="col-2">
                                                              <p>Position</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> : </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{ProfessionalMemberships.position}</p>
                                                            </div>
                                                          </div>

                                                          <div className="row h-20" >
                                                            <div className="col-2">
                                                              <p>Location</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> : </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{ProfessionalMemberships.location}</p>
                                                            </div>
                                                          </div>
                                                          <div className="row" >
                                                            <div className="col-2">
                                                              <p>Tenure</p>
                                                            </div>
                                                            <div className="col-1">
                                                              <p> : </p>
                                                            </div>
                                                            <div className="col-9" >
                                                              <p>{(ProfessionalMemberships.tenure).replace('----', "")} - {(ProfessionalMemberships.tenure1).replace('----', "")}</p>
                                                            </div>
                                                          </div>


                                                          {

                                                            //alert(index)
                                                            (index < this.state.ProfessionalMemberships.length - 1) ? (
                                                              <hr></hr>

                                                            ) : (<div></div>)
                                                          }


                                                        </div>

                                                      ))} */}


                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (<div>
                                          </div>)
                                        }
                                        {/* {(this.state.PressItemFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Press:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='title'
                                                      data={this.state.PressItem} columns={this.state.pressColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                        {(this.state.AdvisoryBoardFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Advisory Board:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='company'
                                                      data={this.state.AdvisoryBoard} columns={this.state.advisoryBoardColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                    {/* {this.state.AdvisoryBoard.map((AdvisoryBoard, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20" >
                                                          <div className="col-2">
                                                            <p>Tenure</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{(AdvisoryBoard.year).replace('----', "")} - {(AdvisoryBoard.to_year).replace('----', "")}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Company</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{AdvisoryBoard.company}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Company Type</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{AdvisoryBoard.type_of_company}</p>
                                                          </div>
                                                        </div>


                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Advisory Board</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{AdvisoryBoard.advisory_board}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Topic</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{AdvisoryBoard.topic}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-2">
                                                            <p>Role</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{AdvisoryBoard.role_id}</p>
                                                          </div>
                                                        </div>

                                                        {

                                                          (index < this.state.AdvisoryBoard.length - 1) ? (
                                                            <hr></hr>

                                                          ) : (<div></div>)
                                                        }
                                                      </div>

                                                    ))} */}


                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {/* {(this.state.AwardsAndHonoursFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Awards & Honours:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='awards_honours'
                                                      data={this.state.AwardsHonours} columns={this.state.awardsHonoursColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                        {/* {(this.state.BooksAndMonographFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Books & Monographs:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.BooksMonographs} columns={this.state.booksMonographColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                        {(this.state.CommiteesFlag === true) ? (
                                          <div >
                                            <h2 className="headings mt-4">Committees:</h2>
                                            <div className="container-fluid ">
                                              <div className="row mt-1 " >
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails" >
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='awards_honours'
                                                      data={this.state.Commitees} columns={this.state.commiteesColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                    {/* {this.state.Commitees.map((Commitees, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20" >
                                                          <div className="col-2">
                                                            <p>Tenure</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{(Commitees.year).replace('----', "")} - {(Commitees.to_year).replace('----', "")}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Body/Org Name</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Commitees.body_org_name}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20" >
                                                          <div className="col-2">
                                                            <p>Committee</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Commitees.committee}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row" >
                                                          <div className="col-2">
                                                            <p>Role</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Commitees.role_id}</p>
                                                          </div>
                                                        </div>
                                                        {
                                                          (index < this.state.Commitees.length - 1) ? (
                                                            <hr></hr>
                                                          ) : (<div></div>)
                                                        }
                                                      </div>
                                                    ))} */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {(this.state.ClinicalTrialDetailsFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Clinical Trial:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    {this.state.ClinicalTrialDetails.map((ClinicalTrialDetails, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20">
                                                          <div className="col-2">
                                                            <p>Study Details</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.study_details}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Start Year</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.start_date.split('-')[0]}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>End Year</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.end_date.split('-')[0]}</p>
                                                          </div>
                                                        </div>


                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Type</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.type}</p>
                                                          </div>
                                                        </div>




                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Phase</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.phase}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Role</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.role_id}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>No. of study Locations</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.no_of_study_locations}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Sponsor</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.sponsors}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Condition</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.conditions}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row">
                                                          <div className="col-2">
                                                            <p>Intervention details</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{ClinicalTrialDetails.treatment_intervention}</p>
                                                          </div>
                                                        </div>

                                                        {

                                                          (index < this.state.ClinicalTrialDetails.length - 1) ? (
                                                            <hr></hr>

                                                          ) : (<div></div>)
                                                        }
                                                      </div>

                                                    ))}


                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {(this.state.EventsDetailsFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Events:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.EventsDetails} columns={this.state.EventsDetailsColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                    {/* {this.state.EventsDetails.map((EventsDetails, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20">
                                                          <div className="col-2">
                                                            <p>Event Name</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.events}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Event Type</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.event_type}</p>
                                                          </div>
                                                        </div>

                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Event Description</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.event_desc}</p>
                                                          </div>
                                                        </div>


                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Event Topic</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.event_topic}</p>
                                                          </div>
                                                        </div>


                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Date</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.date}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Session Type</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.session_type}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Role</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.role_id}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-2">
                                                            <p>Event Sponsor </p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{EventsDetails.event_sponsor}</p>
                                                          </div>
                                                        </div>

                                                        {

                                                          (index < this.state.EventsDetails.length - 1) ? (
                                                            <hr></hr>

                                                          ) : (<div></div>)
                                                        }
                                                      </div>

                                                    ))} */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {(this.state.GrantsFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Grants:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.Grants} columns={this.state.GrantsColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                    {/* {this.state.Grants.map((Grants, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20">
                                                          <div className="col-2">
                                                            <p>Company/Organisation</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Grants.company}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Title</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Grants.title}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Tenure</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{(Grants.year).replace('----', "")} - {(Grants.to_year).replace('----', "")}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Amount</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Grants.amount}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-2">
                                                            <p>Co-Investigator</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Grants.co_investigator}</p>
                                                          </div>
                                                        </div>

                                                        {

                                                          (index < this.state.Grants.length - 1) ? (
                                                            <hr></hr>

                                                          ) : (<div></div>)
                                                        }
                                                      </div>

                                                    ))} */}


                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {(this.state.GuidelinesFlag === true) ? (

                                          <div>

                                            <h2 className="headings mt-4">Guidelines:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.Guidelines} columns={this.state.guidelinesColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                    {/* {this.state.Guidelines.map((Guidelines, index) => (
                                                      <div className="card-body3" data-index={index}>
                                                        <div className="row mt-3 h-20">
                                                          <div className="col-2">
                                                            <p>Year</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> :  </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Guidelines.year}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Organisation</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Guidelines.organization}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Type of Organisation</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Guidelines.type_of_organization}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row h-20">
                                                          <div className="col-2">
                                                            <p>Title</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Guidelines.title}</p>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-2">
                                                            <p>Role</p>
                                                          </div>
                                                          <div className="col-1">
                                                            <p> : </p>
                                                          </div>
                                                          <div className="col-9" >
                                                            <p>{Guidelines.role}</p>
                                                          </div>
                                                        </div>
                                                        {
                                                          (index < this.state.Guidelines.length - 1) ? (
                                                            <hr></hr>
                                                          ) : (<div></div>)
                                                        }
                                                      </div>

                                                    ))} */}


                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        }
                                        {/* {(this.state.PresentationsFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4" >Presentations:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor bcolor"  >
                                                  <div className="card_not nDetails" >
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.Presentation} columns={this.state.presentationsColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                        {/* {(this.state.HospitalAffiliationsFlag === true) ? (
                                          <div>
                                            <h2 className="headings mt-4">Hospital Affiliations:</h2>
                                            <div className="container-fluid">
                                              <div className="row mt-1">
                                                <div className="col-12 mt-2 bcolor">
                                                  <div className="card_not nDetails">
                                                    <BootstrapTable
                                                      noDataIndication="Table is Empty"
                                                      keyField='year'
                                                      data={this.state.HospitalAffiliations} columns={this.state.hospitalAffiliationColumn}
                                                      rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                      hover
                                                      condensed
                                                      borderless
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (<div>
                                        </div>)
                                        } */}
                                      </div>
                                      {(this.state.PublicationDetailsFlag === true) ? (
                                        <div id="table_publication" className="shunshine_publication_background" >
                                          <h2 className="headings mt-4">Publications:</h2>
                                          <div className="container-fluid">
                                            <div className="row mt-1">
                                              <div className="col-12 mt-2 bcolor">
                                                <div className="card_not nDetails">
                                                  <BootstrapTable
                                                    noDataIndication="Table is Empty"
                                                    keyField='year'
                                                    data={this.state.PublicationDetails} columns={this.state.publicationColumn}
                                                    rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                    hover
                                                    condensed
                                                    borderless
                                                  />
                                                  {/* {this.state.PublicationDetails.map((PublicationDetails, index) => (
                                                    <div className="card-body3" data-index={index}>
                                                      <div className="row mt-3 h-20">
                                                        <div className="col-2">
                                                          <p>Journal</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> :  </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.journal}</p>
                                                        </div>
                                                      </div>

                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Type of Journal/Publication</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.type_of_journal}</p>
                                                        </div>
                                                      </div>

                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Publication Title</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.publication_title}</p>
                                                        </div>
                                                      </div>

                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Date</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.date}</p>
                                                        </div>
                                                      </div>
                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Position</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.position}</p>
                                                        </div>
                                                      </div>
                                                      <div className="row">
                                                        <div className="col-2">
                                                          <p>Co-Author</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{PublicationDetails.co_author}</p>
                                                        </div>
                                                      </div>


                                                      {

                                                        (index < this.state.PublicationDetails.length - 1) ? (
                                                          <hr></hr>

                                                        ) : (<div></div>)
                                                      }
                                                    </div>

                                                  ))} */}


                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      ) : (<div>
                                      </div>)
                                      }
                                      {(this.state.SunshinePaymentFlag === true) ? (
                                        <div id="table_shunshine" className="shunshine_publication_background">
                                          <h2 className="headings mt-4">Sunshine Payment:</h2>
                                          <div className="container-fluid">
                                            <div className="row mt-1" >
                                              <div className="col-12 mt-2 bcolor">
                                                <div className="card_not nDetails">
                                                  {/* sunshinePaymentColumn */}
                                                  <BootstrapTable
                                                    noDataIndication="Table is Empty"
                                                    keyField='year'
                                                    data={this.state.SunshinePayment} columns={this.state.sunshinePaymentColumn}
                                                    rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                                    hover
                                                    condensed
                                                    borderless
                                                  />
                                                  {/* {this.state.SunshinePayment.map((SunshinePayment, index) => (
                                                    <div className="card-body3" data-index={index}>
                                                      <div className="row mt-3 h-20">
                                                        <div className="col-2">
                                                          <p>Date</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> :  </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{SunshinePayment.date}</p>
                                                        </div>
                                                      </div>

                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Type</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{SunshinePayment.type}</p>
                                                        </div>
                                                      </div>

                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Location</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{SunshinePayment.location}</p>
                                                        </div>
                                                      </div>


                                                      <div className="row h-20">
                                                        <div className="col-2">
                                                          <p>Amount</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{SunshinePayment.amount}</p>
                                                        </div>
                                                      </div>


                                                      <div className="row">
                                                        <div className="col-2">
                                                          <p>Payment Type</p>
                                                        </div>
                                                        <div className="col-1">
                                                          <p> : </p>
                                                        </div>
                                                        <div className="col-9" >
                                                          <p>{SunshinePayment.payment_type}</p>
                                                        </div>
                                                      </div>


                                                      {

                                                        (index < this.state.SunshinePayment.length - 1) ? (
                                                          <hr></hr>

                                                        ) : (<div></div>)
                                                      }

                                                    </div>

                                                  ))} */}


                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (<div>
                                      </div>)
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (<div></div>)

                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <footer class="footer_not">

                        <div class="cetas-footer">
                            <div class="container">
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
                    </footer> */}
        </div>
      </body>
    );

  }
}

export default ClientViewProfile;