import React, { Component, useRef , useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import Signupinfo from './Signupinfo';
import Pagefooter from './footer';

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectSignup:false,
      redirectSignin:false,
      redirecthome:false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,
      
      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      
      kdidentificationhidediv:false,
      kdprofilinghidediv:false,
      kdinteracthidediv:false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      termconditionAgree:false,

      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UsernameErr: '',
      UseremailErr:'',
      CompanyNameErr:'',      
      PasswordErr: '',
      confirmpasswordErr: '',
      confirmpassword:'',
      termconditionErr:'',

      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      signupData: {
        client_name: '',
        email_id: '',
        company_nm: '',
        password: '',
        signup_method:'',
        status:'New',
       // loginType: 'pre_client',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };
    
    //this.login = this.login.bind(this);
    
   // this.setState({redirect: true})


  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
  
    let getSession_quotaiton_id = sessionStorage.getItem('quotation_id')
    
    if (getSession_quotaiton_id !== null) {        
      let { signupData } = this.state;
      signupData.signup_method = 'quotation';
      signupData.quotation_id=getSession_quotaiton_id;
      this.setState({ signupData });
      
    }
    else
    {
      let { signupData } = this.state;
      signupData.signup_method = 'Portal';
      signupData.quotation_id='';
      this.setState({ signupData });
      
    }
    
  }
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal()
  {
    
      this.setState({redirectSignup:true})
    

  }

  redirectSignin()
  {
    this.setState({redirectSignin:true})
    
  }

  validate = () => {
    let UsernameErr = '';
    let PasswordErr = '';
    
    if (!this.state.loginData.client_id) {
      UsernameErr = 'Please Enter User Id';
    }
    if (!this.state.loginData.password) {
      PasswordErr = 'Please Enter Password';
    }
    
    if (UsernameErr || PasswordErr ) {
      this.setState({ UsernameErr, PasswordErr });
      return false;
    }
    return true;
  }

  validate = () => {
    let UsernameErr = '';
    let UseremailErr = '';
    let CompanyNameErr = '';
    let PasswordErr = '';
    let confirmpasswordErr = '';
    let termconditionErr='';

    if (!this.state.signupData.client_name) {
      UsernameErr = 'Please Enter Full Name';
    }
    if (!this.state.signupData.email_id) {
      UseremailErr = 'Please Enter Email id';
    }

    if (!this.state.signupData.company_nm) {
      CompanyNameErr = 'Please Enter Company Name';
    }
    
    if (!this.state.signupData.password) {
      PasswordErr = 'Please Enter Password';
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(this.state.signupData.password)) {
      PasswordErr = 'Password must be at least 8 characters long and contain at least one number, one uppercase, and one lowercase letter';
    }
    
    if (!this.state.confirmpassword) {
      confirmpasswordErr = 'Please Enter Confirm Password';
    }
    
    if (!this.state.termconditionAgree) {
      termconditionErr = 'Please Accept term and condition';
    }

    if (UsernameErr || UseremailErr ||CompanyNameErr || PasswordErr || confirmpasswordErr || termconditionErr) {
      this.setState({ UsernameErr, PasswordErr ,UseremailErr,CompanyNameErr,confirmpasswordErr,termconditionErr });
      return false;
    }
    
    return true;
  }


  passmatchfunc()
  {
    if(this.state.signupData.password != this.state.confirmpassword)
    {
        this.setState({
          confirmpasswordErr:"Passwords don't match",
        })
       
    }
    else
    {
      this.setState({
        confirmpasswordErr:'',
        confirmpasswordErr:"Passwords match",
        color:'green'
      })
    }
  }

checkboxHandler = (event) => {
      this.setState({ termconditionAgree: !this.state.termconditionAgree})
  }


UserSignupfun() {

    //const isValid =this.validate();
    const isValid ="true";
    
    if (isValid) {
     
      this.setState({
        ShowBar: true,
      })

      if(this.state.signupData.password != this.state.confirmpassword)
      {
          this.setState({
            confirmpasswordErr:"Passwords don't match",
            ShowBar: false,
            UsernameErr:'',
            UseremailErr:'',
            CompanyNameErr:'',
            PasswordErr:'',         
          
          })
         
      }else
      {


      const url = global.websiteUrl+'/service/KOL_landing/save_signup_data';
      let formData = new FormData();

      formData.append('Signup_Info', JSON.stringify(this.state.signupData));
      
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
       
        //  alert(response.data.status);

          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

           
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: ' Thank you for sharing your details. Your account is under verification. Our experts will get back to you within 24 hours.',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })

              sessionStorage.removeItem('quotation_id','');
             // this.setState({infoQuoteModal:false})
            // this.setState({redirect: true})
        
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({redirecthome:true})
          
          
        })
        .catch(error => {
          console.log(error);
        });

      
    }
  }

    }

  makespaceondivkdidentification()
  {
    
    this.setState({

      hidedivkdprofiling:false,
      hidedivkdinteract:false,
      hidedivkdidentification:true,
      


    });

  }

  makespaceondivkdprofiling()
  {
    
    this.setState({

      hidedivkdidentification:false,
      hidedivkdinteract:false,
      hidedivkdprofiling:true,

    });

  }


  makespaceondivkdinteract()
  {
    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:true,

    });

  }

  

  makespaceondivhome()
  {    
    this.setState({
      hidedivkdidentification:false,
      hidedivkdprofiling:false,
      hidedivkdinteract:false,

    });
  }

  
  render() {

   if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
  }

    if (this.state.redirectSignin) {
        return (<Redirect to={'/user login'}/>)
    }

    if (this.state.redirecthome) {
      return (<Redirect to={'/home'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    // // Speciality Type options 
    // let speciality_type = this.state.SpecialityData;
    // let OptionsSpeciality = speciality_type.map((speciality_type) =>
    //   <option key={speciality_type.id} value={speciality_type.id}>
    //     {speciality_type.speciality_type}</option>
    // );

    // // Procedure List
    // let procedure_list = this.state.ProcedureData;
    // let OptionProcedure = procedure_list.map((procedure_list) =>
    //   <option key={procedure_list.name} value={procedure_list.name}>
    //     {procedure_list.procedure_type}</option>
    // );

    // // Sub Speciality List
    // let subSpeciality_list = this.state.SubSpecialityData;
    // let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
    //   <option key={subSpeciality_list.name} value={subSpeciality_list.name}>
    //     {subSpeciality_list.sub_speciality_type}</option>
    // );

    // // Country List
    // let Country_list = this.state.CountryListData;
    // let OptionsCountry = Country_list.map((Country_list) =>
    //   <option key={Country_list.id} value={Country_list.id}>
    //     {Country_list.country_name}</option>
    // );

    // // State List
    // let state_list = this.state.StateData;
    // let OptionsState = state_list.map((state_list) =>
    //   <option key={state_list.name} value={state_list.name}>
    //     {state_list.state_name}</option>
    // );

    return (

        
      <body id="landing-body vh-100" style={{ backgroundColor:'#ccc'}}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
  {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
{/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
  {AddLibrary('./js/app.js')}
  


<div style={{ backgroundColor:'#e9ecef', marginTop:'0px', paddingTop:'40px'}}>        
       
     <LoginHeader/>

    <section id="services" className="text-center" >
    
        <div className="container" >
            <div className="row">
                <div className="col-12" >
                    <div className="login" style={{paddingTop:'2%',paddingBottom:'2%',paddingLeft:'1%',paddingRight:'1%'}}>

                    <div className='mb-5 pb-3'>
                                    <h4>Create New Account !</h4>
                                    <p>Get your free Cetas Healthcare account now</p>
                                  </div>
                                  <div >                                  
                                  <Signupinfo />
                                  </div>                                            
                    </div>
                    <div className="signuplink">
                <p>Already have an account ?  <a href='#' onClick={ this.redirectSignin.bind(this)} >Signin</a></p>
                </div>
                  
                </div>
            </div>            
           
        </div>
       </section>
      
   <Pagefooter/>     
   
</div>
    



  

    
    



      {/* </header> */}
 
      </body>
    );
  }
}

export default Signup;