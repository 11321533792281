import React, { Component } from "react";

import { Redirect } from "react-router-dom";

//datatable npm
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

// showing progress bar npm
import { PropagateLoader } from "react-spinners";
import LoadingBar from "react-top-loading-bar";

import imgSrc from "../Assets/Images/bg_banner1.jpg";

import axios from "axios";

import Swal from "sweetalert2";
import Axios from "axios";
import { Multiselect } from "react-widgets";

import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import ClientHeader from "./ClientHeader";
import Signup from "../Login/Signupinfo";
//import { color } from 'html2canvas/dist/types/css/types/color';
//import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';

// import '../styles/ViewProject.css';

import Pagefooter from "../Login/footer";

import Quoteinfo from "../PClient/Quoteform";
import Quoteform from "../PClient/Quoteform";

var jwt=require('jsonwebtoken');
var jwtkey= require('../config');

//export const toggleClosePopup=()=>{alert('hi Aarna')};
export const otherpageClosePopup = () => {
  window.location.reload(true);
};

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: "checkbox",

  // clickToSelect: true
};

const { ExportCSVButton } = CSVExport;

class ClientViewKolList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: "",
      client_name: "",
      client_email: "",
      pretoken:'',
      project_id: "CHCPI006",
      // project_id:'CHCPI0078',
      quotation_id: "",
      Search_speciality_id: "",
      search_speciality_name: "",
      Buyer_order: "",
      Buyer_kol_id: "",
      Buyer_client_name: "",
      Buyer_client_id: "",
      KOL_Profile: [],
      searchResultCount: 0,
      QuoteData: {
        client_id: "",
        client_name: "",
        kol_id: "",
        no_of_deep_profile: "",
        no_of_basic_profile: "",
        no_of_kol: "",
        specialty_id: "",
        procedure_type: "",
        countrieslist: [],
        Multiple_single_profile: "",
        status: "New",
      },
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        country: "",
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },
      procedureshowtype: false,

      redirecthome: false,
      //
      // RedirectEmployeeKolList: false,
      // RedirectClientProject: false,
      redirectClientKolProfile: false,

      redirectClientViewProfile: false,
      redirect: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,

      QuoteModal: false,
      RedirectSignUp: false,

      SignupModal: false,

      //toggleSignupModal:false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      Profile: [],
      speciality_type_type: "",
      subSpeciality: sessionStorage.getItem("subspeciality") || "",
      procedure: sessionStorage.getItem("procedure") || "",
      // to set form values
      Speciality: "",

      SubSpecialityType: [],
      SubSpecialityTypeString: "",
      ProcedureType: [],
      ProcedureTypeString: "",
      Subspeciality: [],

      showProcedureFlag: true,
      showSubSpecialityFlag: true,
      multiplekolquotmodel: false,
      redirectknowmore: false,
      infoQuoteModal: false,

      NewQuoteModal: false,
      // searchg modal
      SearchModal: false,
      PrilimaryModal: false,

      // Column state for Bootstrap Table
      //var headerSortingStyle = { backgroundColor: "#e3edf8" };

      columns: [
        // {
        //     dataField: 'kol_unique_id',
        //     text: 'ID',
        // },
        // {
        //   dataField: 'rank_local',
        //   text: 'Ranking',
        //   sort: true,

        //   formatter: (cell, row) => {
        //     return(
        //     <div class="P_Client">

        //          {row.rank_local}

        //       </div>
        //     )

        //    },

        //    headerStyle: (colum, colIndex) => {
        //     return {width: '3%', borderColor:'#02365F',textAlign:'left', fontSize:'13px', borderLeft:'solid', borderLeftColor:'#fff', borderTop:'solid', borderTopColor:'#fff' };
        //   },
        //   //formatter: this.RankLocalFormater,
        //   // //classNames:'text-center',
        //   // class:'text-center',
        //   // cellStyle: {color: 'red', 'background-color': 'green'},

        // },

        {
          dataField: "kol_unique_id",
          text: "KOL ID",
          formatter: (cell, row) => {
            return <div class="P_Client">{row.kol_unique_id}</div>;
          },
          sort: true,
          hidden: true,
          headerStyle: (colum, colIndex) => {
            return {
              width: "6%",
              borderColor: "#02365F",
              textAlign: "center",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },

        // {
        //   dataField: 'doctor_full_name',
        //   text: 'KOL Name',
        //  // formatter: this.linkToDetails,
        //   // sort: true,
        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onProjectNameFilter = onFilter;
        //     return null;
        //   },
        //   headerStyle: (colum, colIndex) => {
        //     return {width: '22%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', fontWeight:'bold', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        // },
        {
          dataField: "sample",
          //text: "Image",
          formatter: () => {
            return (<div style={{ verticalAlign: "middle", fontWeight: "bold", textDecoration: "underline" }}>
              <td>Sample KOL</td>
            </div>)
          },
          headerStyle: (colum, colIndex) => {
            return {
              width: "10%",
              borderColor: "#02365F",
              paddingLeft: "3.5%",
              textAlign: "left",
              fontSize: "15px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },
        {
          dataField: "profile_photo",
          //text: "Image",
          formatter: this.PfrofileImageFormater,
          headerStyle: (colum, colIndex) => {
            return {
              width: "10%",
              borderColor: "#02365F",
              paddingLeft: "3.5%",
              textAlign: "left",
              fontSize: "15px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },
        {
          dataField: "aggregate_score",
          text: "Rank",
          formatter: (cell, row) => {
            return <div class="P_Client">{row.aggregate_score}</div>;
          },
          sort: true,
          hidden: true,
          headerStyle: (colum, colIndex) => {
            return {
              width: "10%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "13px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
        },
        {
          dataField: "doctor_full_name",
          text: "KOL Name",

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onSpecityFilter = onFilter;
            return null;
          },
          headerStyle: (colum, colIndex) => {
            return {
              width: "28%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "15px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          formatter: (cell, row) => {
            return <div class="P_Client">{row.doctor_full_name}</div>;
          },
        },

        //

        // {
        //   dataField: "procedure_type",
        //   text: "Procedure Type",
        //   formatter:this.procedureformate,
        //   // formatter: (cell, row) => {
        //   //   return(
        //   //   <div class="P_Client">
        //   //       {row.procedure_type}

        //   //   </div>
        //   //   )

        //   //  },
        //    headerStyle: (colum, colIndex) => {
        //     return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        //  // hidden: true,
        // },

        // {
        //   dataField: "expertise",
        //   text: "Expertise",
        //   formatter: (cell, row) => {
        //     return(
        //     <div class="P_Client">
        //         {row.expertise}

        //     </div>
        //     )

        //    },

        //   filter: textFilter(),
        //   filterRenderer: (onFilter, column) => {
        //     this.onInstitutionFilter = onFilter;
        //     return null;
        //   },

        //    headerStyle: (colum, colIndex) => {
        //     return {width: '20%',borderColor:'#02365F',textAlign:'left', fontSize:'15px', borderTop:'solid', borderTopColor:'#fff'};
        //   },
        //  // hidden: true,
        // },
        {
          dataField: "medical_schools",
          text: "Institution",
          formatter: (cell, row) => {
            return <div class="P_Client">{row.medical_schools}</div>;
          },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onInstitutionFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {
              width: "28%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "15px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          // hidden: true,
        },
        {
          dataField: "country_name",
          text: "Country",
          formatter: (cell, row) => {
            return <div class="P_Client">{row.country_name}</div>;
          },

          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onCountryFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return {
              width: "28%",
              borderColor: "#02365F",
              textAlign: "left",
              fontSize: "15px",
              borderTop: "solid",
              borderTopColor: "#fff",
            };
          },
          //hidden: true,
        },
        // {
        //   dataField: 'aggregate_score',
        //   text: 'Rating',
        //    formatter:this.rating,
        //   headerStyle: (colum, colIndex) => {
        //     return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
        //   },

        // },

        {
          dataField: "",
          text: "",
          formatter: this.KOLDetails,

          csvExport: false,
          headerStyle: (colum, colIndex) => {
            return {
              width: "5%",
              borderColor: "#02365F",
              textAlign: "center",
              borderTop: "solid",
              borderTopColor: "#fff",
              borderRight: "solid",
              borderRightColor: "#fff",
            };
          },
        },
      ],
    }; // End of states
  } // End of constructor


  validate = () => {
    let noofkolErr = "";
    let noofbasickolErr = "";
    let noofdeepkolErr = "";
    let specilityErr = "";
    let procedureErr = "";
    let countryErr = "";
    // let profilingtypeErr='';

    // if (!this.state.QuoteData.Multiple_single_profile) {
    //   Multiple_single_profileErr = 'Please select';
    // }

    // if (!this.state.QuoteData.no_of_kol) {
    //   noofkolErr = 'Please Enter no of kol required';
    // }
    // if (!this.state.QuoteData.procedure_type) {
    //     procedureErr = 'Please select procedure';
    //  }

    if (!this.state.QuoteData.no_of_basic_profile) {
      noofbasickolErr = "Please input no of basic profile";
    }

    if (!this.state.QuoteData.no_of_deep_profile) {
      noofdeepkolErr = "Please input no of deep profile";
    }

    if (this.state.QuoteData.countrieslist.length <= 0) {
      countryErr = "Please select country";
    }

    if (!this.state.QuoteData.specialty_id) {
      specilityErr = "Please select speciality";
    }

    // if (!this.state.QuoteData.Profilingtype) {
    //   profilingtypeErr = 'Please select profiling type';
    // }

    // if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr ) {
    //   this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr });
    //   return false;
    // }

    if (noofbasickolErr || countryErr || specilityErr || noofdeepkolErr) {
      this.setState({
        noofbasickolErr,
        specilityErr,
        countryErr,
        noofdeepkolErr,
      });
      return false;
    }

    return true;
  };

  SaveQuoteinfo = async () => {
    //const no_of_basic_profile=Number(this.state.QuoteData.no_of_basic_profile);
    //const no_of_deep_profile=Number(this.state.QuoteData.no_of_deep_profile);

    //  alert(this.state.QuoteData.no_of_kol);
    const isValid = this.validate();
    //     const isValid =false;
    if (isValid) {
      this.state.QuoteData.no_of_kol =
        Number(this.state.QuoteData.no_of_basic_profile) +
        Number(this.state.QuoteData.no_of_deep_profile);

      this.setState({
        ShowBar: true,
      });

      const url = global.websiteUrl + "/service/KOL_landing/save_quote_data";
      let formData = new FormData();

      formData.append("Quote_Info", JSON.stringify(this.state.QuoteData));

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message);

          this.setState({
            ShowBar: false,
          });
          if (response.data.status == true) {
            if (this.state.client_id != "Guest") {
              Swal.fire({
                icon: "success",
                //text: response.data.message,
                text: " Thanks for quote,Our experts will get back to you within 24 hours.!",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
              });

              this.setState({ infoQuoteModal: false, QuoteModal: false });
            } else {
              sessionStorage.setItem(
                "quotation_id",
                response.data.quotation_id
              );

              this.setState({
                infoQuoteModal: false,
                QuoteModal: false,
                SignupModal: true,
                quotation_id: response.data.quotation_id,
              });
              //this.setState({ RedirectSignUp: true })
            }
          } else {
            Swal.fire({
              icon: "error",
              text: response.data.message,
              confirmButtonColor: "#d33",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({
        pnameErr: "",
        psowErr: "",
      });
    }
  };

  redirectknowmore = async () => {
    this.setState({ redirectknowmore: true });
    // alert('redirectknowmorehi');
  };

  onChangeValue = async (event) => {
    if (event.target.value == "BuyMultipleProfiles") {
      let { QuoteData } = this.state;
      QuoteData.kol_id = "";
      this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({
        multiplekolquotmodel: true,
        Multiple_single_profileErr: "",
      });
    } else {
      let { QuoteData } = this.state;
      QuoteData.kol_id = this.state.kol_id;
      QuoteData.no_of_kol = "";
      QuoteData.no_of_deep_profile = "";
      QuoteData.no_of_basic_profile = "";
      QuoteData.specialty_id = "";
      QuoteData.procedure_type = "";
      // QuoteData.Profilingtype='';
      QuoteData.countrieslist = "";
      this.setState({ QuoteData });

      // let { QuoteData } = this.state;
      // QuoteData.kol_id =this.state.kol_id;
      // this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({
        multiplekolquotmodel: false,
        //noofkolErr: '',
        noofbasickolErr: "",
        noofdeepkolErr: "",
        specilityErr: "",
        procedureErr: "",
        countryErr: "",
        Multiple_single_profileErr: "",
        // profilingtypeErr:'',
      });
    }

    // alert(event.target.value);
    let { QuoteData } = this.state;
    QuoteData.Multiple_single_profile = event.target.value;
    this.setState({ QuoteData });
    console.log(event.target.value);
  };

  componentDidMount = async () => {

    let getSession_Serch_speciality_id = await sessionStorage.getItem(
      "speciality_id"
    );

  //  alert(getSession_Serch_speciality_id)

    let getSession_wtoken = sessionStorage.getItem('wtoken')
    jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {     
      if(!err && response.login_type =="pre_client" && getSession_Serch_speciality_id !==null)
          { 
            
            let getSession_pretoken = sessionStorage.getItem("token");
            if (getSession_pretoken !== null) {
             this.setState({
               pretoken: getSession_pretoken
             });
            }
            else
            {
              this.setState({ redirect: true })
            }
    
            let getSession_Buyer_order = await sessionStorage.getItem("Buyer_order");
    
            if (getSession_Buyer_order !== null) {
              await this.setState({
                Buyer_order: getSession_Buyer_order,
              });
            }
    
        // sessionStorage.setItem('Buyer_kol_id',this.state.kol_id);
        let getSession_Buyer_kol_id = await sessionStorage.getItem("Buyer_kol_id");
        if (getSession_Buyer_kol_id !== null) {
          await this.setState({
            Buyer_kol_id: getSession_Buyer_kol_id,
          });
        }
        // sessionStorage.setItem('Buyer_client_name',this.state.client_name);
        let getSession_Buyer_client_name = await sessionStorage.getItem(
          "Buyer_client_name"
        );
        if (getSession_Buyer_client_name !== null) {
          await this.setState({
            Buyer_client_name: getSession_Buyer_client_name,
          });
        }
        // sessionStorage.setItem('Buyer_client_id',this.state.client_id);
    
        let getSession_Buyer_client_id = await sessionStorage.getItem(
          "Buyer_client_id"
        );
        if (getSession_Buyer_client_id !== null) {
          await this.setState({
            Buyer_client_id: getSession_Buyer_client_id,
          });
        }
    
       

        let getSession_serch_speciality_name = sessionStorage.getItem(
          "speciality"
        );
        if (getSession_Serch_speciality_id !== null) {
          this.setState({
            Search_speciality_id: getSession_Serch_speciality_id,
            search_speciality_name: getSession_serch_speciality_name
          });
        }
    
        if (this.state.Buyer_order == "NewBuyOrder") {
          //alert("order for kol");
          this.setState({ QuoteModal: true });
        }
    
        // console.log("getSession_Project_id ====", getSession_Project_id)
    
        this.setState({
          ShowBar: true,
        });
        const searchResultUrl = global.websiteUrl + "/service/Client/get_total_searched_kol_no"
          , formDataSearchResult = new FormData()
          , configSearchResult = {}
          formDataSearchResult.append('token_id',getSession_pretoken);
        
        axios
          .post(searchResultUrl, formDataSearchResult, configSearchResult)
          .then((response) => {
            console.log("response of Search Result Count", response);
            if (response.data.status == false) {
              console.log("status false..!!");
            } else if (response.data.status == true) {
              console.log("status true..!!");
              this.setState({ searchResultCount: response.data.no_of_kol })
              // this.state.searchResultCount = response.data.no_of_kol;
            }
            // console.log("Length of Kol List ",this.state.Profile.length);
          })
          .catch((error) => {
            console.log(error);
          });
    
       
        const url1 =
          global.websiteUrl + "/service/KOL_landing/Get_Kol_List_On_Speciality";
        let formData1 = new FormData();
        console.log(this.state.Search_speciality_id)
        formData1.append("specialty_id", this.state.Search_speciality_id);
        formData1.append("token_id", this.state.pretoken);
    
        
        const config1 = {
          //   headers: { 'content-type': 'multipart/form-data' }
        };
    
        axios.post(url1, formData1, config1).then((response) => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
    
          if (response.data.status == true) {
            // // console.log(response.data.kol_info)
            // // console.log(response.data.kol_info)
            // let dummyData = [
            //   {
            //     "kol_unique_id": "CHCUI004",
            //     "doctor_full_name": "Dr. Ananya Arora",
            //     "procedure_type_id": "NA",
            //     "experience": "12 years",
            //     "expertise": "",
            //     "speciality_type": "Cardiac Nursing",
            //     "country_name": "US",
            //     "medical_schools": "Harvard University",
            //     "profile_photo": "Heidi_Rehm1.jpg",
            //     "aggregate_score": "1"
            //   },
            //   {
            //     "kol_unique_id": "CHCUI004",
            //     "doctor_full_name": "Prof. George M Weinstock",
            //     "procedure_type_id": "NA",
            //     "experience": "12 years",
            //     "expertise": "",
            //     "speciality_type": "Cardiac Nursing",
            //     "country_name": "US",
            //     "medical_schools": "Harvard University",
            //     "profile_photo": "Heidi_Rehm1.jpg",
            //     "aggregate_score": "1"
            //   }
            // ]
            this.setState({
              KOL_Profile: response.data.kol_info,
              speciality_type_type: response.data.kol_info[0].speciality_type,
              ShowBar: false,
            });
          }
        });
    


          }          
          else
          {
            Swal.fire({
              icon: 'error',
              title: jwtkey.jwt.session_expired_msg_title,
              text: jwtkey.jwt.wrong_url_msg,
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
    
            sessionStorage.removeItem('wtoken');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('login_type');
            
            sessionStorage.removeItem("speciality_id");
            this.setState({ redirect: true })
    
          }
        });
        

    
       
  };


  toggleGetQuoteModal(kolIdForCredential) {
    // alert(kolIdForCredential);

    this.setState({
      infoQuoteModal: !this.state.infoQuoteModal,
      //PasswordErr: '',
    });
  }

  toggleGetQuoteModal(kolIdForCredential) {
    this.setState({
      NewQuoteModal: !this.state.NewQuoteModal,
    });
  }

  toggleSignupModal() {
    let idtoDelete = this.state.quotation_id;
    // alert(idtoDelete);
    if (idtoDelete !== "") {
      this.setState({
        SignupModal: !this.state.SignupModal,
        //toggleSignupModal:!this.state.toggleSignupModal,
      });

      this.setState({
        ShowProcedure: true,
      });
      const url = global.websiteUrl + "/service/KOL_Landing/delete_quote_data";

      let formData = new FormData();
      // formData.append('oper', "del");
      formData.append("id", idtoDelete);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response.data);
          // alert(response.data.status);
          if (response.data.status == true) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  delete_quote_id(id) {
    console.log(id);
    let idtoDelete = JSON.parse(id);

    this.setState({
      ShowProcedure: true,
    });
    const url = global.websiteUrl + "/service/KOL_Landing/delete_quote_data";

    let formData = new FormData();
    formData.append("oper", "del");
    formData.append("id", idtoDelete);

    const config = {
      // headers: { 'content-type': 'multipart/form-data' }
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  //Funtion to apply links to project names style={{backgroundColour:'#A51E22',borderColor:'#A51E22'}}
  rating = (cell, row) => {
    var kol_id = row.kol_unique_id;

    return (
      <div class="hexagon">
        <span class="hexL">{row.aggregate_score}</span>
      </div>
    );
  };

  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking = row.aggregate_score;

    return (
      <div>
        <a
          style={{
            color: "#069",
            fontSize: "15px",
            fontWeight: "bold",
            textAlign: "left",
            textDecoration: "underline",
            cursor: "pointer",
            position: "relative",
            marginTop: "20px",
            display: "inline-block",
          }}
          onClick={this.profileInfoNav.bind(this, kol_id, raking)}
        >
          {" "}
          {row.doctor_full_name}{" "}
        </a>
      </div>
    );
  };

  //Funtion to apply links to Biography
  linkToBio = (cell, row) => {
    var kol_id = row.kol_unique_id;

    return (
      <div>
        <a
          style={{
            color: "#069",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={this.bioInfoNav.bind(this, kol_id)}
        >
          {" "}
          {row.doctor_full_name}{" "}
        </a>
      </div>
    );
  };

  RankLocalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a
          style={{
            color: "#000",
          }}
        >
          {row.rank_local}
        </a>
      </div>
    );
  };

  procedureformate = (cell, row) => {
    var procedure = row.procedure_type;
    let pro = procedure.split(",");
    var finalprocedure = pro[0];

    if (pro.length > 1) {
      finalprocedure += ", " + pro[1] + " and more…";
      //alert(pro[1]);
    }
    return <div class="P_Client">{finalprocedure}</div>;
  };

  PfrofileImageFormater = (cell, row) => {
    var imgsource = ""
      , host = window.location.origin
      , imagePath = `${host}/ProfileImage/`
    // alert(row.profile_photo);
    if (row.profile_photo !== null) {
      imgsource = imagePath + row.profile_photo;
    } else {
      imgsource = "require('../Assets/Images/default-user.jpg')";
    }
    console.log({ imgsource })
    return (
      <div>
        <td><img src={imgsource} class="Client_profile_pic" /></td>
      </div>
    );
  };

  toggleQuoteModal = async () => {
    this.setState({ QuoteModal: true });
  };

  toggleinfoBuyModal = () => {
    this.setState({
      NewQuoteModal: !this.state.NewQuoteModal,
    });
  };

  togglecanQuoteinfoModal = async () => {
    this.setState({ infoQuoteModal: false });
  };

  profileInfoNav(kol_id, raking) {
    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of clientKolProfile", kol_id);
    this.setState({
      redirectClientKolProfile: true,
    });
  }

  bioViewNav(kol_id, raking) {
    // if(raking !=='NA')
    //   {

    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of ClientViewBio", kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewBiography: true,
    });
    //  }
    //   else
    //   {
    //    this.setState({
    //       PrilimaryModal:true,
    //    })

    //   }
  }

  profileViewNav(kol_id, raking) {
    // if(raking !=='NA')
    // {

    sessionStorage.setItem("kol_id", JSON.stringify(kol_id));
    console.log("Navigation of ClientViewProfile", kol_id);
    this.setState({
      //redirectClientKolProfile: true,
      redirectClientViewProfile: true,
    });

    // }
    // else
    // {
    //  this.setState({
    //     PrilimaryModal:true,
    //  })

    // }
  }

  CVViewNav(kol_id, raking, resumeurl) {
    // if(raking !=='NA')
    // {
    window.open(resumeurl, "_blank");
  }


  KOLDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    var raking = row.aggregate_score;

    return (
      <div>
        <a onClick={this.profileInfoNav.bind(this, kol_id, raking)}>
          <button
            class="btn1 btn-danger btn-block btn-sm btn-labeled"
            style={{ width: "150px", marginTop: "20px", height: "40px" }}
          >
            View Sample Profile
          </button>
        </a>
      </div>
    );
  };

  // Funtion for displaying 'bio', 'Profile' & 'CV' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <td class="butns1">
          {
            // <a
            //   onClick={this.bioViewNav.bind(this,kol_id,rating)}>
            //   <button class="btn1 btn-danger btn-block btn-sm btn-labeled" style={{width:'100px'}}>
            //     Bio
            //   </button>
            // </a>
          }
          {/* {          
            <a               
            onClick={this.profileViewNav.bind(this, kol_id,rating)}> 
              <button class="btn1 btn-danger btn-block btn-sm btn-labeled">
              Profile 
              </button>
            </a>
        } */}
          {/* { 
         
            (row.resume !== null )? ( 
                // <a href={ row.resume } target="_blank"> 
                <a onClick={this.CVViewNav.bind(this, kol_id,rating,row.resume)}> 
               
                  <button class="btn1 btn-danger btn-block btn-sm btn-labeled" >
                    CV
                  </button>
                </a>

            
            ):
            (<div></div>)
          
           
        } */}
        </td>
      </div>
    );
  };

  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  onSpecilityChange = (event) => {
    const { value } = event.target;
    this.onSpecityFilter(value);
  };

  onInstitutionChange = (event) => {
    const { value } = event.target;
    this.onInstitutionFilter(value);
  };

  onexpertiseChange = (event) => {
    const { value } = event.target;
    this.onexpertiseFilter(value);
  };

  onCountryChange = (event) => {
    const { value } = event.target;
    this.onCountryFilter(value);
  };

  BackToClientProject() {
    this.setState({ RedirectClientProject: true });
  }

  logout = () => {
    console.log("in logout");
    this.setState({ redirect: true });
    sessionStorage.removeItem("client_id");
    sessionStorage.removeItem("client_name");
    sessionStorage.removeItem("email_id");
  };

  // search modal functions
  toggleSearchModal = () => {
    this.setState({
      SearchModal: !this.state.SearchModal,
      ProcedureType: [],
      ProcedureTypeString: "",
      SubSpecialityType: [],
      SubSpecialityTypeString: "",
    });
  };

  // search modal functions
  togglePrilimaryModal = () => {
    this.setState({
      PrilimaryModal: !this.state.PrilimaryModal,
    });
  };

  toggleBackToHome() {
    this.setState({ redirecthome: true });
  }

  render() {
    if (this.state.redirectClientKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/Sample Profile",
          }}
        />
      );
    }

    if (this.state.RedirectSignUp) {
      return (
        <Redirect
          to={{
            pathname: "/SignUp",
          }}
        />
      );
    }

    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }

    if (this.state.redirectknowmore) {
      return (
        <Redirect
          to={{
            pathname: "/Profiletypedetails",
          }}
        />
      );
    }
    if (this.state.redirecthome) {
      return (
        <Redirect
          to={{
            pathname: "/Home",
          }}
        />
      );
    }

    // Speciality Type options
    //let speciality_type = this.state.specialityData;
    let speciality_type = this.state.SpecialityData;

    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      // <option key={speciality_type.id} value={speciality_type.id}>
      //   {speciality_type.speciality_type}</option>
      <option key={speciality_type} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) => (
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}
      </option>
    ));

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange,
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick}>
          <a class="page-link" href="#">
            {page}
          </a>
        </li>
      );
    };
    const submitKolForm = (searchType = 'Clinical', data = {}, closeModel) => {
      this.setState({
        ShowBar: true,
      });
      console.log(data)
      const country = data?.country ? data.country.map(c => c.value).toString() : ""
        , sub_specialty_id = data?.subSpeciality ? data.subSpeciality.map(ssp => ssp.value).toString() : ""
        , specialty_id = data?.speciality ? data.speciality.map(sp => sp.value).toString() : ""
        , procedure = data.procedureType == "Procedure" && data?.procedure ? data.procedure.map(p => p.value).toString() : ""
        , condition = data.procedureType == "Condition" && data?.procedure ? data.procedure.map(p => p.value).toString() : ""
      console.log({ country, sub_specialty_id, specialty_id, procedure, condition })
      if (searchType = 'Clinical') {
        const url1 =
          global.websiteUrl + "/service/KOL_landing/Get_Kol_List_On_Speciality";
        let formData1 = new FormData();
        formData1.append("specialty_id", specialty_id);
        formData1.append("country_id", country);
        formData1.append("sub_specialty_id", sub_specialty_id);
        formData1.append("procedure_type_id", procedure);
        formData1.append("condition_type_id", condition);
        formData1.append('token_id',this.state.pretoken);
   
        const config1 = {
          //   headers: { 'content-type': 'multipart/form-data' }
        };

        axios.post(url1, formData1, config1).then((response) => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          if (response.data.status == true) {
            this.setState({
              Profile: response.data.kol_info,
              speciality_type_type: response.data.kol_info[0].speciality_type,
              ShowBar: false,
            });
            closeModel()
          } else {
            this.setState({
              Profile: [],
              speciality_type_type: "",
              ShowBar: false
            });
            closeModel()
          }
        }, err => {
          this.setState({
            Profile: [],
            speciality_type_type: "",
            ShowBar: false
          });
          closeModel()
        });
      } else {
        console.log('Non Clinical Speciality')
      }
    }


    return (
      <div
        style={{
          backgroundColor: "#F9F9F9",
          paddingBottom: "35px",
          marginTop: "-10px",
          paddingTop: "30px",
        }}
      >
        {this.state.ShowBar ? (
          <Modal isOpen={true} centered>
            <PropagateLoader color={"#A90007"} size="30px" loading="true" />
          </Modal>
        ) : null}
        {this.state.progressBar ? (
          <div style={{ position: "fixed", top: "0" }}>
            <LoadingBar
              progress="100"
              height={3}
              color="#A90007"
              transitionTime={4000}
              loaderSpeed={3000}
            />
          </div>
        ) : null}
        <div>
          <ClientHeader onSubmitHandler={submitKolForm} />
          <div
            class="container-fluid"
            id="mainClientDashboard"
            style={{ width: "98%" }}
          >
            <div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <h4
                  style={{
                    height: "30px",
                    fontSize: "20px",
                    fontWeight: "bolder",
                    width: "70%",
                    display: "inline-block",
                  }}
                >
                  {this.state.search_speciality_name}
                  {this.state.subSpeciality !== undefined && this.state.subSpeciality !== 'undefined' && this.state.subSpeciality !== 'NA' ? `/${this.state.subSpeciality}` : ''}
                  {this.state.procedure !== undefined && this.state.procedure !== 'undefined' && this.state.procedure !== 'NA' ? `/${this.state.procedure}` : ''} {"KOLs"}

                </h4>
                <h4
                  style={{
                    height: "30px",
                    float: "right",
                    textAlign: "right",
                    fontSize: "15px",
                    width: "20%",
                    display: "inline-block",
                  }}
                >
                  <p>
                    <a href="#" onClick={this.toggleBackToHome.bind(this)}>
                      Home
                    </a>{" "}
                    <b>
                      <span class="btn-label">
                        <i class="fa fa-caret-right"></i>{" "}
                      </span>
                    </b>{" "}
                    Opinion Leaders
                  </p>
                </h4>
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                    <div class="card">
                      <div class="card-body">
                        <div className="gridtable">
                          <span className="text text-muted" style={{ fontSize: "14px", fontWeight: "bold" }}>{this.state.searchResultCount} results..</span>

                          {/* <h4 style={{height:'30px' , fontSize:'20px', fontWeight:'bolder' }} >"{this.state.speciality_type_type}" KOLs </h4>
                           */}
                          <table class="table table-hover">
                            <ToolkitProvider
                              keyField="kol_unique_id"
                              data={this.state.KOL_Profile}
                              columns={this.state.columns}
                              search={this.customMatchFunc}
                           
                            >
                              {(props) => (
                                <div>
                                  <BootstrapTable
                                    {...props.baseProps}
                                    defaultSortDirection="asc"
                                    filter={filterFactory()}
                                    hover
                                    condensed
                                    tabIndexCell
                                    rowStyle={(row, rowIndex) => {
                                      if (rowIndex == 0) {
                                        return {
                                          backgroundColor: "White",
                                          borderLeft: "solid",
                                          borderLeftColor: "#fff",
                                          borderBottom: "solid",
                                          borderBottomColor: "#EDE9E8",
                                          borderRight: "solid",
                                          borderRightColor: "#fff",
                                        }
                                      } else {
                                        return {
                                          background: 'white',
                                          filter: 'blur(4px)'
                                        }
                                      }
                                    }}
                                  
                                  />
                                  <br />
                                </div>
                              )}
                            </ToolkitProvider>
                          </table>

                          {/* <div class="blurimage"></div> */}

                          <div
                            className="blurimage_button"
                            style={{ position: "relative", zIndex: "1000", marginTop: "0px" }}
                          >
                            <a onClick={this.toggleGetQuoteModal.bind(this)}>
                              {/* <a onClick={this.toggleSignupModal.bind(this)} > */}

                              <button
                                class="btn1 btn-danger btn-sm btn-labeled"
                                style={{
                                  width: "220px",
                                  fontSize: "15px",
                                  height: "60px",
                                  borderRadius: 0,
                                  textDecoration: "underline",
                                }}
                              >
                                View more profiles
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* kdkd */}
          <Modal
            isOpen={this.state.NewQuoteModal}
            toggle={this.toggleinfoBuyModal.bind(this)}
            centered
          >
            <ModalHeader
              toggle={this.toggleinfoBuyModal.bind(this)}
              cssModule={{
                "modal-title": "w-100 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              <h3
                style={{ justifyContent: "center", marginTop: "10px" }}
                id="login-tit"
              >
                Get a quote
              </h3>
            </ModalHeader>

            {/* <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' , backgroundColor:'#ccc' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '20px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader> */}

            <ModalBody style={{ padding: "0px 70px 0px 70px" }}>
              <Quoteform></Quoteform>
            </ModalBody>
            {/* <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.togglecanQuoteinfoModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.SaveQuoteinfo.bind(this)}>
                                                Next
                                            </button>

                                        </ModalFooter> */}
          </Modal>

          {/* PrilimaryModal */}

          <Modal
            isOpen={this.state.PrilimaryModal}
            toggle={this.togglePrilimaryModal.bind(this)}
            centered
          >
            <ModalHeader
              toggle={this.togglePrilimaryModal.bind(this)}
              cssModule={{
                "modal-title": "w-200 text-center",
                "border-bottom": "0px",
              }}
              style={{ borderBottom: "0px" }}
            >
              {/* <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Search KOL</h3> */}
            </ModalHeader>

            <ModalBody>
              <FormGroup>
                <div className="center">
                  <h3>
                    {" "}
                    <u>Contact</u>
                  </h3>
                </div>
                <div className="center">
                  <h4>
                    <a style={{ color: "blue" }}> xyz@cetas-healthcare.com</a>
                  </h4>
                </div>

                <div className="center">
                  <h4></h4>
                </div>
              </FormGroup>
            </ModalBody>
          </Modal>

         
        </div>
        <Pagefooter />
      </div>
    );
  }
}

export default ClientViewKolList;
