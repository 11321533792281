import React, { Component, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';

class footer extends Component{
    constructor(props)
    {
        super(props);
        this.state=
        {
            Redirect:false,
        };

     
    
    }

    togglehome() {
        this.setState({
        Redirect:true,
        });

    };
    render(){

        if(this.state.Redirect)
        {
            return(<Redirect to={'/'}/>)
        }

        return(
            <div className="text-center" >
                <div className="container" >
                    <div className="row">
                        <div className="col-12">
                        <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas-healthcare.com/privacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas-healthcare.com/terms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas-healthcare.com/cookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> */}
                        <span className="mb-0">{global.CopyRightText}</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
                        </div>
                    </div>            
                
                </div>
            </div>

   
        )
    }
}
export default footer;