import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';

//import '../styles/ClientHeader.css';
//import './Client.css';

class Profiletypedetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
            client_id: '',
            client_name: '',
            client_email: '',
            kol_id:'',
            project_id:'',
            speciality_id:'',
            speciality_name:'',

            SpecialityData:[],
        };        
    }

    componentDidMount = async () => {
    
    }

    toggleSearchModal()
    {
      
    //  alert(this.state.speciality_name);
     // this.setState({redirecthome:true})
      
  
    }


    

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }

        return (


            
        <div class="Client-header1" style={{marginTop:'50px'}} > 
         <h1> Profile type details</h1>
           {/* <div className="header-nav" >
                <div className="container">
                 <nav class="navbar navbar-light navbar-expand-lg fixed-top" >
                    <div class="container-fluid">
                           <h1> Profile type details</h1>
                  </div>
                  </nav>
                                        


                      </div>

    
                   
                </div> */}
            </div> 
        );
    }
}

export default Profiletypedetails;