import React, { Component } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
// import * as htmlToImage from 'html-to-image';

import Routes from '../Routes';

import { BrowserRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
import ApexChart from './ApexChart';
import Swal from 'sweetalert2';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import '../styles/biography.scss';

import ClientHeader from './ClientHeader';
import context from 'react-bootstrap-table2-editor/lib/src/context';
import { PropagateLoader } from 'react-spinners';
//import LoadingBar from "react-top-loading-bar";

var jwt=require('jsonwebtoken');
var jwtkey= require('../config');
class ClientViewBio extends Component {

  constructor(props) {
    var IMGNAME = "";
    super(props);
    this.state = {

      RedirectFlag: false,

      biographyFlag: false,
      exportbuttonFlag: false,
      // for image
      file: '',
      imagePreviewUrl: '',
      client_name: '',
      completeAddress: '',
      secondryEmail: '',

      searchInCetas: false,
      redirect: false,
      kol_id: '',
      project_id: '',
      client_token:'',
      redirectClientViewKolList: false,
      redirectDashboard: false,
      redirectSearchInCetasDb: false,
      RedirectLogin: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      itemsBiography: [],
      Profile: [],
      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      Biography: {},
      
      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',
      client_token:'',

      CountryListData: [],
      StateData: [],
      CityData: [],
      EmployeeListData: [],

      

    }




  }




  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('kol_id')
    
  }

  getPhoto = event => {
    event.preventDefault();
    console.log("photo event", event.target.files[0])

    if (event.target.files[0]) {
      let reader = new FileReader();
      let file = event.target.files[0];

      // this.setState({file: e.target.files[0],})
      console.log("file my", this.state.profile_photo);
      reader.onloadend = () => {
        this.setState({
          profile_photo: file,
          imagePreviewUrl: reader.result,
          profile_photo_for_edit: '',

        });
      }
      reader.readAsDataURL(file);
    }
  }


  printpdf = () => {
    var element = document.getElementById('printablediv');
    var opt = {
      margin: [10, 30, 30, 30],
      filename: 'Bio_' + this.state.PersonalInformation.doctor_full_name + '.pdf',
      image: { type: 'image/PNG', quality: 2 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: 'pt', format: 'A4', orientation: 'landscape' }
    };
    // // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
    //alert("download")

    // const input = document.getElementById('printablediv');
    // html2canvas(input)
    //   .then((canvas) => {

    //               var imgWidth = 210;
    //               var pageHeight = 290;
    //               var imgHeight = canvas.height * imgWidth / canvas.width;
    //               var heightLeft = imgHeight;
    //               var doc = new jsPDF('p', 'mm','A4');
    //               //var position = 5;
    //               var pageData = canvas.toDataURL('image/jpeg', 1.0);
    //               var imgData = encodeURIComponent(pageData);
    //               doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //             //  // doc.setLineWidth(5);
    //             //   //doc.setDrawColor(255, 255, 255);
    //             //   //doc.rect(0, 0, 210, 295);

    //             //   heightLeft -= pageHeight;

    //             //   while (heightLeft >= 0) {
    //             //     position = heightLeft - imgHeight;
    //             //     doc.addPage();
    //             //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //             //     // doc.setLineWidth(5);
    //             //     // doc.setDrawColor(255, 255, 255);
    //             //     // doc.rect(0, 0, 210, 295);
    //             //     //doc.setFillColor(255, 255, 255);
    //             //      heightLeft -= pageHeight;
    //             //   }
    //               //doc.save('file.pdf');
    //               doc.save('Bio_'+this.state.PersonalInformation.doctor_full_name+'.pdf');
    //             });




  }


  renderkolPreview() {
    //  let path="https://app.medtech-gurus.com/upload_files/Picture13.jpg";
    //alert(this.state.profile_photo);
    //https://app.medtech-gurus.com/upload_files/nathaniel-buzolic-the-originals.webp
    //https://cetas.medtech-gurus.com/Cetas/upload_files/Picture11.jpg
    if (this.state.profile_photo !== null && this.state.profile_photo !== '') {
      var pathsplit = this.state.profile_photo;
      //  let pathfinal ='https://mobilesutra.com/Cetas/upload_files/James_Hadfield_Photo.png';
      //https://mobilesutra.com/Cetas/upload_files/Stephen_Kinsgmore.jpg
      if (pathsplit.length > 4) {
        if (pathsplit[2] === "cetas.medtech-gurus.com") {
          this.state.IMGNAME = pathsplit[5];
        }
        else if (pathsplit[2] === "app.medtech-gurus.com") {
          this.state.IMGNAME = pathsplit[4];
        }
      }

      if (this.state.profile_photo_for_edit) {
        const host = window.location.origin
        , imagePath = `${global.websiteUrl}/ProfileImage/`
        
        //, imagePath = `${host}/Cetas/ProfileImage/`
          //, imagePath = `${host}/ProfileImage/`
          console.log('imgpath',imagePath);
        return (
          <img src={imagePath + this.state.profile_photo} className="biography-Logo" alt={this.state.PersonalInformation.doctor_full_name} />
        );
      } else {
        return (
          <p>
            <img src={require('../Assets/Images/default-user.jpg')} className="biography-Logo" alt={this.state.PersonalInformation.doctor_full_name} />
          </p>
        );
      }
    }
    else {
      return (
        <p>
          <img src={require('../Assets/Images/default-user.jpg')} className="biography-Logo" alt={this.state.PersonalInformation.doctor_full_name} />
        </p>
      );
    }
  }







  componentDidMount = async () => {


    let getSession_wtoken = await sessionStorage.getItem('wtoken')
    console.log('tok', getSession_wtoken);

    jwt.verify(getSession_wtoken, jwtkey.jwt.key, async (err, response) => {
      if (!err && (response.login_type =="client" || response.login_type =="client_demo"))
        {
 

          let getSession_Client_token = await sessionStorage.getItem('token')
          if (getSession_Client_token !== null) {
            await this.setState({
              client_token: getSession_Client_token,
            })
          }


          let getSession_Kol_Id = sessionStorage.getItem('kol_id')
          if (getSession_Kol_Id !== null) {
            this.setState({
              kol_id: JSON.parse(getSession_Kol_Id),
            })
          }
          else {
            this.setState({ redirect: true });
          }


          let getSession_Project_Id = await sessionStorage.getItem('project_id')
          if (getSession_Project_Id !== null) {
            await this.setState({
              project_id: getSession_Project_Id,
            })
          }

          console.log("getSession_kol_id ====", this.state.kol_id);
          console.log("getSession_Project_Id ===== ", getSession_Project_Id);



          this.setState({
            ShowBar: true,
            progressBar: true,
          })

          const options = {
            method: 'POST',
          }


          const url = global.websiteUrl + '/service/Client/get_kol_bio_details';
          let formData = new FormData();
          formData.append('kol_id', this.state.kol_id);
          formData.append('project_id', getSession_Project_Id);
          formData.append('token_id',this.state.client_token);

          const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
          }
          axios.post(url, formData, config)
            .then(response => {
              console.log(response);
              var lgdata = response.data;
              console.log("lgdata=====", JSON.stringify(lgdata));
              var KOL_Personal_Info = response.data.KOL_Biography;
            // console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));

              this.setState({

                PersonalInformation:KOL_Personal_Info,
                Biography:KOL_Personal_Info,
                profile_photo_for_edit: response.data.KOL_Profile_Photo,         
                profile_photo: response.data.KOL_Profile_Photo,         

              })

              this.setState({
                ShowBar: false,
                biographyFlag: true,
                exportbuttonFlag: true,
                // ContractFileName: ContractFileNameapi
              })

              if (this.state.PersonalInformation.doctor_email2 !== "") {
                this.setState({

                  secondryEmail: ", " + this.state.PersonalInformation.doctor_email2,
                })

              }


              if (this.state.PersonalInformation.address_1 !== "") {
                this.setState({
                  completeAddress: this.state.PersonalInformation.address_1,

                })

              }


              if (this.state.PersonalInformation.address_2 !== "") {

                this.setState({
                  completeAddress: this.state.completeAddress + ', ' + this.state.PersonalInformation.address_2,

                })

              }

              //city,country_id,zip_code

              if (this.state.PersonalInformation.city !== "") {
                this.setState({
                  completeAddress: this.state.completeAddress + ', ' + this.state.PersonalInformation.city,
                })
              }
            })


            .catch(error => {
              console.log(error);
              alert("Error Occurred");
              this.setState({
                ShowBar: false,
                progressBar: false,
              });
            });


          }
          else {
            
            Swal.fire({
              icon: 'error',
              title: jwtkey.jwt.session_expired_msg_title,
              text: jwtkey.jwt.wrong_url_msg,
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('wtoken');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('login_type');            
            sessionStorage.removeItem("speciality_id");
            this.setState({ redirect: true })
          }
    })

        }


        formatPhoneNumber(value) {
          // if input value is falsy eg if the user deletes the input, then just return
          if (!value) return value;

          // clean the input for any non-digit values.
          const phoneNumber = value.replace(/[^\d]/g, '');

          // phoneNumberLength is used to know when to apply our formatting for the phone number
          const phoneNumberLength = phoneNumber.length;

          // we need to return the value with no formatting if its less then four digits
          // this is to avoid weird behavior that occurs if you  format the area code to early

          if (phoneNumberLength < 4) return phoneNumber;

          // if phoneNumberLength is greater than 4 and less the 7 we start to return
          // the formatted number
          if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
          }

          // finally, if the phoneNumberLength is greater then seven, we add the last
          // bit of formatting and return it.
          if (phoneNumberLength == 10) {
            return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
              3,
              6
            )}-${phoneNumber.slice(6, 10)}`;
          }
          else if (phoneNumberLength == 11) {
            return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
              1,
              4
            )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
          }
          else if (phoneNumberLength == 12) {
            return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
              2,
              5
            )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

          }
          else {

            return + phoneNumber
          }


          

  }



  // rediect funcs
  BackToDashboard() {
    this.setState({ redirectDashboard: true })
  }


  BackToKolList() {

    this.setState({ redirectClientViewKolList: true })
  }



  render() {

    if (this.state.redirectDashboard) {
      return (<Redirect to={{
        pathname: '/My Dashboard',
      }} />)
    }

    if (this.state.redirectClientViewKolList) {
      return (<Redirect to={{
        pathname: '/My Database',
      }} />)
    }


    return (
      <body >
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}

        <div >
          <div>
            <div style={{ marginTop: '0px', backgroundColor: 'white' }}>

              <ClientHeader ClientName={this.state.client_name} />

              <div class="container" >

                <div class="biography-backoptiopn">

                  <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3" >
                  <p > {sessionStorage.getItem('login_type') == 'client_demo' ?
                      <div>
                      </div> : <>
                        <a href="#" onClick={this.BackToDashboard.bind(this)}>
                          Dashboard
                        </a>{" "}
                        <b><span class="btn-label"><i class="fa fa-caret-right"></i></span></b>
                      </>
                    } <a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile</p>
                 
                    {/* <p ><a href='#' onClick={this.BackToDashboard.bind(this)} >Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b><a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Biography</p> */}
                  </div>

                </div>

              </div> 



   
                {(this.state.exportbuttonFlag === true) ?
                (
                  <div style={{ position: "fixed", zIndex: 1, right: "10%", top: "26%" }}>
                    <button className="btn btn-primary" onClick={this.printpdf.bind(this)}>Export to PDF!</button>
                  </div>
                )
                :
                (<div></div>)
              }

              <div className="biography" id='printablediv'  >

                <div className="container">

                <div class="screen-headingbox"> <h2>Biography
                </h2></div><br />
                  {
                    (this.state.biographyFlag === true) ?
                      (
                        <div className="row" >
                          <div className="col-md-12 main_col">
                            <h3>{this.state.PersonalInformation.salutation} {this.state.PersonalInformation.doctor_full_name},{this.state.PersonalInformation.qualification}</h3>
                            <div className="row">
                              <div className="col-md-3 col-sm-3 col-lg-3 main_col">
                                <div className="container imgCont"><br></br>
                                  <div className="container-fluid pic_cont_not" style={{ textAlign: 'center' }}>
                                    <div class="img-box" style={{ textalign: "left" }}>
                                      {this.renderkolPreview()}
                                    </div>
                                  </div>
                                  <div className="imgData" style={{ textalign: "left", float: "left" }} >
                                    <br></br>
                                    <p >{this.state.PersonalInformation.experience} Experience</p>
                                    <p >NPI: {this.state.PersonalInformation.NPI_no}</p>
                                    <p >Speciality: {this.state.PersonalInformation.specialty_id}</p>
                                    {/* <p >Address: {this.state.PersonalInformation.address_1}, {this.state.PersonalInformation.address_2}, {this.state.PersonalInformation.City}, {this.state.PersonalInformation.country}, {this.state.PersonalInformation.zipcode}</p> */}
                                    <p >Address: {this.state.completeAddress}, {this.state.PersonalInformation.country_id} - {this.state.PersonalInformation.zip_code} </p>

                                    <p >Phone: {this.formatPhoneNumber(this.state.PersonalInformation.doctor_contact_no)}</p>
                                    <p >Fax: {this.formatPhoneNumber(this.state.PersonalInformation.fax)}</p>
                                    <p >Email Id: <a href="#">{this.state.PersonalInformation.doctor_email}{this.state.secondryEmail}</a></p>
                                    <p >LinkedIn: <a href="#">{this.state.PersonalInformation.linked_in_profile}</a></p>
                                    <p >Twitter: <a href="#">{this.state.PersonalInformation.twitter_handle}</a></p>


                                  </div>
                                </div>

                              </div>
                              <div className="col-md-8 col-sm-8 col-lg-8 colDatas">
                                <div className="container-fluid">
                                  <p className="text-justify font-weight-normal" >
                                    <ul style={{ paddingTop: "5px", marginLeft: '9%' }}>
                                      <view style={{ whiteSpace: "break-spaces", color: "black", textAlign: "justify", marginLeft: '20px' }}>
                                        {this.state.Biography.biography}
                                      </view>
                                    </ul>
                                  </p>
                                </div>

                              </div>
                            </div>

                          </div>



                        </div>
                      ) : (<div></div>)

                  }

                </div>
              </div>
              {/* </header> */}


              <br /><br /><br /><br /><br /><br />
            </div>
          </div>
        </div>
      </body>
    );

  }
}

export default ClientViewBio;