import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
//import ApexChart from './ApexChart';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import ClientHeader from './ClientHeader';
import Signup from '../Login/Signupinfo';
//import 'flag-icon-css/css/flag-icon.min.css';
//import '../styles/summary.css';
// showing progress bar npm 

import Quoteform from '../PClient/Quoteform';

import Pagefooter from '../Login/footer';
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";
import { NumberFormatter } from 'react-number-formatter';

var jwt=require('jsonwebtoken');
var jwtkey= require('../config');


class ClientKolProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kol_id: '',
      client_name: '',
      pretoken:'',
      client_id: '',
      secondryEmail: '',
      quotation_id: '',
      procedureshowtype: false,
      // For validations
      noofkolErr: '',
      specilityErr: '',
      procedureErr: '',
      countryErr: '',
      profilingtypeErr: '',
      Multiple_single_profileErr: '',

      QuoteData: {
        client_id: '',
        client_name: '',
        kol_id: '',
        no_of_deep_profile: 0,
        no_of_basic_profile: 0,
        no_of_kol: '',
        specialty_id: '',
        procedure_type: '',
        countrieslist: [],
        Multiple_single_profile: '',

        status: 'New',

      },
      // state for progress bar
      ShowBar: false,
      progressBar: false,
      QuoteModal: false,
      SignupModal: false,
      NewQuoteModal: false,

      multiplekolquotmodel: false,

      DoctorCompleteName: "",
      ranking: "",
      RedirectSignUp: false,
      RedirectLogin: false,
      redirectknowmore: false,
      redirecthome: false,
      DoctorFormattedContactNumber: "",
      DoctorFormattedFaxNumber: "",
      DoctorFormattedAssistentContactNumber: "",



      // End Chart Color *****************

      // ProfileBySession: this.props.location.state.ProfileBySession,

      RedirectFlag: false,

      redirectBackToDashboard: false,
      redirectBackToKolList: false,

      //Flag to show form of selected drawer item
      PersonalInformationFlag: true,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,

      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],

      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',

      CountryListData: [],
      ProcedureData: [],
      SpecialityData: [],

      StateData: [],
      CityData: [],
      EmployeeListData: [],

      // KolRatingScore data
      event_kol_rating: [],
      publication_kol_rating: [],
      ad_board_kol_rating: [],
      guideline_kol_rating: [],
      clinicaltrial_kol_rating: [],

      //piechart data
      event_piechart: [],
      ad_board_piechart: [],
      publication_piechart: [],
      guideline_piechart: [],
      clinicaltrial_piechart: [],

      home_piechart_flag: true,


      // *************** New Chart Data **********************



      // all columns 
      columnsWorkDetails: [
        {
          dataField: 'current_place_of_work',
          text: 'Current Place of Work',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            // return { textAlign: 'center', width: '10%' };
            return { width: '10%' };
          },
        },
        {
          dataField: 'designation',
          text: 'Designation',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'from_year',
          text: 'From',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'to_year',
          text: 'To',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],




    };
  }

  //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: true,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  EventPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  }
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  }

  // Function for drawer side selection
  PersonalInformationFun = async () => {
    await this.setState({
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PersonalInformationFlag: true,
    })
  }

  validate = () => {
    let noofkolErr = '';
    let noofbasickolErr = '';
    let noofdeepkolErr = '';
    let specilityErr = '';
    let procedureErr = '';
    let countryErr = '';
    let Multiple_single_profileErr = '';


    if (this.state.QuoteData.Multiple_single_profile == "BuyMultipleProfiles") {
      if (!this.state.QuoteData.Multiple_single_profile) {
        Multiple_single_profileErr = 'Please select';
      }

      if (!this.state.QuoteData.no_of_basic_profile) {
        noofbasickolErr = 'Please input no of basic profile';
      }

      if (!this.state.QuoteData.no_of_deep_profile) {
        noofdeepkolErr = 'Please input no of deep profile';
      }

      if (this.state.QuoteData.countrieslist.length <= 0) {
        countryErr = 'Please select country';
      }

      if (!this.state.QuoteData.specialty_id) {
        specilityErr = 'Please select speciality';
      }

      // if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr || Multiple_single_profileErr) {
      //   this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr,Multiple_single_profileErr });
      //   return false;
      // }
      if (noofbasickolErr || countryErr || specilityErr || noofdeepkolErr || Multiple_single_profileErr) {
        this.setState({ noofbasickolErr, specilityErr, countryErr, noofdeepkolErr, Multiple_single_profileErr });
        return false;
      }
    }
    else {
      if (!this.state.QuoteData.Multiple_single_profile) {
        Multiple_single_profileErr = 'Please select';
      }
      if (Multiple_single_profileErr) {
        this.setState({ Multiple_single_profileErr });
        return false;
      }
    }


    return true;
  }



  SaveQuoteinfo = async () => {

    const isValid = this.validate();
    //  const isValid =true;
    if (isValid) {

      this.state.QuoteData.no_of_kol = Number(this.state.QuoteData.no_of_basic_profile) + Number(this.state.QuoteData.no_of_deep_profile);

      this.setState({
        ShowBar: true,
      })

      const url = global.websiteUrl + '/service/KOL_landing/save_quote_data';
      let formData = new FormData();

      formData.append('Quote_Info', JSON.stringify(this.state.QuoteData));

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)

          // alert(response.data.status);
          // alert(response.data.quotation_id);


          this.setState({
            ShowBar: false,
          })
          if (response.data.status == true) {

            if (this.state.client_name != "Guest") {
              Swal.fire({
                icon: 'success',
                //text: response.data.message,
                text: ' Thanks for quote,Our experts will get back to you within 24 hours.!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'

              })


              this.setState({ infoQuoteModal: false, QuoteModal: false })


            }
            else {
              // alert(response.data.quotation_id);
              sessionStorage.setItem('quotation_id', response.data.quotation_id);
              this.setState({ infoQuoteModal: false, QuoteModal: false, SignupModal: true, quotation_id: response.data.quotation_id })

              // this.setState({ RedirectSignUp: true })
            }
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        pnameErr: '',
        psowErr: '',
      });
    }

  }


  redirectknowmore = async () => {
    this.setState({ redirectknowmore: true })
    // alert('redirectknowmorehi');
  }

  toggleBackToHome() {
    this.setState({ redirecthome: true })
  }


  onChangeValue = async (event) => {

    if (event.target.value == "BuyMultipleProfiles") {
      let { QuoteData } = this.state;
      QuoteData.kol_id = '';
      this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({ multiplekolquotmodel: true, Multiple_single_profileErr: '', })
    } else {
      let { QuoteData } = this.state;
      QuoteData.kol_id = this.state.kol_id;
      QuoteData.no_of_kol = '';
      QuoteData.no_of_deep_profile = '';
      QuoteData.no_of_basic_profile = '';
      QuoteData.specialty_id = '';
      QuoteData.procedure_type = '';
      // QuoteData.Profilingtype='';
      QuoteData.countrieslist = '';
      this.setState({ QuoteData });

      this.setState({
        multiplekolquotmodel: false,
        //noofkolErr: '',
        noofbasickolErr: '',
        noofdeepkolErr: '',
        specilityErr: '',
        procedureErr: '',
        countryErr: '',
        Multiple_single_profileErr: '',
        // profilingtypeErr:'',  


      })
    }

    let { QuoteData } = this.state;
    QuoteData.Multiple_single_profile = event.target.value;
    this.setState({ QuoteData });
    console.log(event.target.value);
  }

  toggleinfoBuyModal = async () => {
    this.setState({ QuoteModal: true, })
  }

  toggleDeleteModal() {

    let idtoDelete = this.state.quotation_id;
    // alert(idtoDelete);
    if (idtoDelete !== "") {

      this.setState({
        SignupModal: !this.state.SignupModal,
      })

      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl + '/service/KOL_Landing/delete_quote_data';

      let formData = new FormData();
      // formData.append('oper', "del");
      formData.append('id', idtoDelete);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          // alert(response.data.status);
          if (response.data.status == true) {
           
          }

        })
        .catch(error => {
          console.log(error);
        });

    }
  }

  togglecanQuoteinfoModal = async () => {

    this.setState({ infoQuoteModal: false, })

  }



  toggleBuyModal = async () => {

    sessionStorage.removeItem('Buyer_order')
    sessionStorage.removeItem('Buyer_kol_id')
    sessionStorage.removeItem('Buyer_client_name')
    sessionStorage.removeItem('Buyer_client_id')

    sessionStorage.setItem('Buyer_order', "NewBuyOrder");
    sessionStorage.setItem('Buyer_kol_id', this.state.kol_id);
    sessionStorage.setItem('Buyer_client_name', this.state.client_name);
    sessionStorage.setItem('Buyer_client_id', this.state.client_id);
    this.setState({ infoQuoteModal: true })


  }


  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    if (phoneNumberLength == 10) {
      return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
    else if (phoneNumberLength == 11) {
      return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
        1,
        4
      )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    }
    else if (phoneNumberLength == 12) {
      return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        5
      )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

    }
    else if (phoneNumberLength > 5) {

      return + phoneNumber;
    }

    
  }










  componentDidMount() {


    let getSession_wtoken = sessionStorage.getItem('wtoken')  

    jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {     
      if(!err && response.login_type =="pre_client")
          {       
                  let getSession_Client_Name = sessionStorage.getItem('client_name')
                  if (getSession_Client_Name !== null) {
                    this.setState({
                      client_name: getSession_Client_Name,
                    })
              
                    let { QuoteData } = this.state;
                    QuoteData.client_name = getSession_Client_Name;
                    this.setState({ QuoteData });
              
                  }
              
                  let getSession_client_id = sessionStorage.getItem('client_id')
                  if (getSession_client_id !== null) {
                    this.setState({
                      client_id: getSession_client_id,
                    })
              
                    let { QuoteData } = this.state;
                    QuoteData.client_id = getSession_client_id;
                    this.setState({ QuoteData });
                  }


          let getSession_pretoken = sessionStorage.getItem("token");
              if (getSession_pretoken !== null) {
              this.setState({
                pretoken: getSession_pretoken
              })
              }
              else
              {
                this.setState({ redirect: true })
              }


          let getSession_Kol_Id = sessionStorage.getItem('kol_id')
          if (getSession_Kol_Id !== null) {
            this.setState({
              kol_id: JSON.parse(getSession_Kol_Id),
            })

          }
          else
          {
            this.setState({ redirect: true })
          }


      // console.log("kol_id", this.state.kol_id);
      // console.log("getSession_Kol_Id", getSession_Kol_Id);

      this.setState({
        // LoadingBar:true,
        ShowBar: true,
      })


      // const options = {
      //     method: 'POST',
      // }
      const url = global.websiteUrl + '/service/client/get_SampleKOLInfo';
      let formData = new FormData();
      // formData.append('kol_id','CHCUI001');
      formData.append('kol_id', JSON.parse(getSession_Kol_Id));
      formData.append('token_id', getSession_pretoken);
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }
      const host = window.location.origin
        , imagePath = `${host}/ProfileImage/`
      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata=====", JSON.stringify(lgdata));
          var KOL_Personal_Info = response.data.KOL_Personal_Info;
        // console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
        console.log("WorkDetails==========", JSON.stringify(response.data.KOL_Work_Details));
      
          this.setState({
            PersonalInformation: response.data.KOL_Personal_Info,
            WorkDetails: response.data.KOL_Work_Details,
            //Biography: response.data.KOL_Personal_Info,

            profile_photo_for_edit: `${imagePath + response.data.KOL_Profile_Photo}`,         
            profile_photo: `${imagePath + response.data.KOL_Profile_Photo}`,

          })

          //console.log("WorkDetails==========", JSON.stringify(response.data.KOL_Personal_Info));
      
          this.setState({
            ShowBar: false,
            // ContractFileName: ContractFileNameapi
          })


          if (this.state.PersonalInformation.doctor_email2 !== "") {
            this.setState({

              secondryEmail: ", " + this.state.PersonalInformation.doctor_email2,
            })

          }



          this.setState({

            DoctorCompleteName: this.state.PersonalInformation.salutation + " " + this.state.PersonalInformation.doctor_full_name + ", " + this.state.PersonalInformation.suffix,

          })


          this.setState({ ranking: this.state.PersonalInformation.aggregate_score })
        })

          }
          else
          {
            Swal.fire({
              icon: 'error',
              title: jwtkey.jwt.session_expired_msg_title,
              text: jwtkey.jwt.wrong_url_msg,
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
    
            sessionStorage.removeItem('wtoken');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('login_type');
            
            sessionStorage.removeItem("speciality_id");
            this.setState({ redirect: true })
    
          }

        
        })
    

        



   
  }


  
  // search modal functions
  toggleinfoBuyModal = () => {
    this.setState({
      QuoteModal: !this.state.QuoteModal,

    });
  }

  toggleinfoBuyModal = () => {
    this.setState({
      NewQuoteModal: !this.state.NewQuoteModal,

    });
  }
  // rediect funcs
  BackToDashboard() {
    this.setState({ redirectBackToDashboard: true })
  }

  BackToKolList() {
    this.setState({ redirectBackToKolList: true })

  }

  render() {
    if (this.state.redirectBackToKolList) {
      return (<Redirect to={{
        pathname: '/KOL Directory',
      }} />)
    }

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    if (this.state.RedirectSignUp) {
      return (<Redirect to={{
        pathname: '/create new account',
      }} />)
    }

    if (this.state.redirectLogin) {

      return (<Redirect to={{
        pathname: '/user login',
      }} />)
    }

    if (this.state.redirecthome) {

      return (<Redirect to={{
        pathname: '/Home',
      }} />)
    }

    if (this.state.redirectknowmore) {

      return (<Redirect to={{
        pathname: '/Profiletypedetails',
      }} />)
    }

    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}</option>
    );











    return (

      <div style={{ backgroundColor: '#F9F9F9', paddingBottom: '35px', marginTop: '-10px', paddingTop: '30px' }}>


        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}


        <ClientHeader />


        <div class="container-fluid" id="mainClientDashboard" style={{ width: '98%' }} >
          <div >
            <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
              <div class="row breadcum" >
                <div class="col-lg-6 col-md-6 col-sm-12 col-12" >
                  <h4><b>Profile Details</b></h4>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12"  >
                  {/* <h5> <label className='gbck'><a href='#' onClick={this.BackToKolList.bind(this)} subTitleClassName="caret left" href='p'><i class="arrow left"></i> Go Back</a></label></h5> */}
                  <p ><a href='#' onClick={this.toggleBackToHome.bind(this)}>Home</a><b><span class="btn-label">  </span></b><b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> <a href='#' onClick={this.BackToKolList.bind(this)}>Opinion Leaders</a> <b><span class="btn-label">  </span></b><b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile Details</p>

                  {/* <p ><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p> */}
                </div>
                {/* <hr style={{width:'96.5%' , marginBottom:'10px',height:'.2px', marginLeft:'1%'}}></hr> */}
              </div>

            </div>

            {/* <div class="profile-backoptiopn">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                  <p ><a href='#' onClick={this.BackToDashboard.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b><a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile Details</p>
            </div>
        </div>  */}


            <div class="container-fluid text-dark detailContainer" >

              {/* <h3 className='pfDetails'>Profile Details <hr></hr></h3> */}
              <div className='' >
                {/* <h5 className='pfDetails'><b>Profile Details</b></h5> */}
                {/* <hr style={{maxWidth:'101.5%', minWidth:'101.5%'}} ></hr>  */}

                <div className='row'>
                  <div className='col col-lg-2' >
                    <div className='card mb-2 crd_menu_des'  >
                      <div className='card-body'>
                        <ul className="nav nav-pills">
                          <li className="nav-item pill-1">
                            <a href='#' className="nav-link nav-linkss active" data-bs-toggle="pill">Personal Information
                              {/* {
                                                                (this.state.PersonalInformationFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Personal Information</text>
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Personal Information</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                            {/* <a onClick={this.BiographyFun} >
                                                            {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text>Biography</text>
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                      {/* <a className="nav-link nav-linkss active" data-bs-toggle="pill" href="#personalInformation">Personal Information</a> */}
                          </li>
                          <li className="nav-item pill-2">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Premium Insights</a>
                          </li>
                          <li className="nav-item pill-3">
                            {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Biography">Biography</a> */}
                            <a onClick={this.toggleinfoBuyModal.bind(this)} href='#' className="nav-link nav-linkss" data-bs-toggle="pill" >Biography
                              {/* {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div >
                                                                        <text className="nav-link">
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text className="nav-linkss" >Biography</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                          </li>
                          <li className="nav-item pill-4">
                            {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Professionaldtl">Professional</a> */}
                            <a onClick={this.toggleinfoBuyModal.bind(this)} href='#' className="nav-link nav-linkss " data-bs-toggle="pill">Professional
                              {/* {
                                                                (this.state.ProfessionalMembershipFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Professional Membership</text>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Professional Membership</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                          </li>
                          <li className="nav-item pill-5">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Education</a>
                          </li>
                          <li className="nav-item pill-6">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Advisory Board</a>
                          </li>
                          <li className="nav-item pill-7">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Awards and Honours </a>
                          </li>
                          <li className="nav-item pill-8">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Books</a>
                          </li>
                          <li className="nav-item pill-9">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Committees</a>
                          </li>
                          <li className="nav-item pill-10">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Clinical Trial</a>
                          </li>
                          <li className="nav-item pill-11">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Events</a>
                          </li>
                          <li className="nav-item pill-12">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Grants</a>
                          </li>
                          <li className="nav-item pill-13">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Guidelines</a>
                          </li>
                          <li className="nav-item pill-14">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Hospital Affiliations</a>
                          </li>
                          <li className="nav-item pill-15">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Interactions</a>
                          </li>
                          <li className="nav-item pill-16">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Press Item</a>
                          </li>
                          <li className="nav-item pill-17">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Presentations</a>
                          </li>
                          <li className="nav-item pill-18">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Publications</a>
                          </li>
                          <li className="nav-item pill-19">
                            <a onClick={this.toggleinfoBuyModal.bind(this)} className="nav-link nav-linkss" data-bs-toggle="pill" href="#">Sunshine Payment</a>
                          </li>


                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='col col-lg-10' >
                    <div className='card'>
                      <div className='card-body'  >
                        <div className='row'>
                          <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2'  >
                            <img className='prflpge' src={this.state.profile_photo} alt='KOL' ></img>
                          </div>
                          <div className='col col-lg-8 col-md-8 col-sm-8 col-xs-8' style={{ marginLeft: '-5%' }} >
                            <ul1 className='hdlead'>
                              <li><h6><b>{this.state.DoctorCompleteName}</b></h6></li>
                              <li><b>Speciality: </b>{this.state.PersonalInformation.specialty_id}</li>

                              {(this.state.PersonalInformation.expertise !== "") ? (

                                <li><b>Expertise:</b> {this.state.PersonalInformation.expertise}</li>

                              ) :
                                (<div></div>)

                              }
                              <li><b>Procedure Type:</b> {this.state.PersonalInformation.procedure_type_id}</li>
                            </ul1>
                          </div>
                          <div className='col-2 mt-3' >

                            {/* <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{marginLeft:'4%',backgroundColor:'pink'}}> */}
                            {/* <a onClick={this.toggleGetQuoteModal.bind(this)} >           */}

                            <a onClick={this.toggleinfoBuyModal.bind(this)} >
                              <div className='Buy_now_anchor'>Unlock Profile</div>
                              {/* <button class="btn1 btn-danger btn-sm btn-labeled" style={{width:'140px',height:'40px', fontSize:'12px',marginTop:'10px'}}>
                                    Buy Now
                                  </button> */}
                            </a>

                          </div>
                        </div>

                      </div>
                    </div>
                    {/* <div class="tab-content tbpnsd"> */}
                    <div class="tbpnsd">
                      {/* <a id="overall-details"></a> */}

                      {

                        (this.state.PersonalInformationFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2 active" id="personalInformation">
                            <div className='card scrl_cntnt '>
                              <div className='card-body' >
                                <h6><b>Personal Details</b></h6><hr></hr>
                                <div className='row' style={{filter: 'blur(3px)'}}>
                                  <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                                    <p className='pbio'>
                                      <div className="pbio_head"><i class="fa fa-address-card fa-lg" /> Basic Information</div>
                                      <li className='biogr_li'><b>Alma Mater: </b>{this.state.PersonalInformation.medical_schools}</li>
                                      <li className='biogr_li'><b>Degree: </b>{this.state.PersonalInformation.qualification}</li>
                                      <li className='biogr_li'><b>Clinical Experience: </b>{this.state.PersonalInformation.experience}</li>
                                      <li className='biogr_li'><b>License No: </b>{this.state.PersonalInformation.medical_registration_no}</li>
                                      <li className='biogr_li'><b>NPI No: </b>{this.state.PersonalInformation.NPI_no}</li>
                                    </p>
                                </div>
                                  <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge' style={{filter: 'blur(3px)'}}>

                                    <p className='pbio'>

                                      <div className="pbio_head"><i class="fa fa-phone fa-lg" /> Contact Information</div>
                                      <li className='biogr_li'><b>Contact No: </b>
                                        {
                                          (this.state.PersonalInformation.doctor_contact_no !== "") ?
                                            (
                                              this.formatPhoneNumber(this.state.PersonalInformation.doctor_contact_no)
                                              //this.state.PersonalInformation.doctor_contact_no
                                            ) : (<div></div>)
                                        }
                                        {/* { (this.state.PersonalInformation.doctor_contact_no !=="")?
                           (
                          
                              }}
                              
                              

                            />
                            </label>
                           ):(<div></div>)
                          } */}
                                      </li>

                                      <li className='biogr_li'><b>Fax  No: </b>
                                        {
                                          (this.state.PersonalInformation.fax !== "") ?
                                            (
                                              this.formatPhoneNumber(this.state.PersonalInformation.fax)
                                              //this.state.PersonalInformation.doctor_contact_no
                                            ) : (<div></div>)
                                        }

                                      </li>

                                      <li className='biogr_li'><b>Email: </b><a href={"mailto:" + this.state.PersonalInformation.doctor_email}> {this.state.PersonalInformation.doctor_email}</a></li>
                                      <li className='biogr_li'><b>Secondary Email: </b><a href={"mailto:" + this.state.PersonalInformation.doctor_email2}> {this.state.PersonalInformation.doctor_email2}</a></li>
                                      {/* <li className='biogr_li'><b>Assistant/Secretary Contact No: </b>{this.state.PersonalInformation.assistance_contact_no}</li> */}

                                      <li className='biogr_li'><b>Assistant/Secretary Contact No: </b>

                                        {
                                          (this.state.PersonalInformation.assistance_contact_no !== "") ?
                                            (
                                              this.formatPhoneNumber(this.state.PersonalInformation.assistance_contact_no)
                                              //this.state.PersonalInformation.doctor_contact_no
                                            ) : (<div></div>)
                                        }
                                      </li>



                                      <li className='biogr_li'><b>Assistant Mail: </b><a href={"mailto:" + this.state.PersonalInformation.assistance_email}> {this.state.PersonalInformation.assistance_email}</a></li>
                                    </p>
                                  </div>
                                  <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                                    <p className='pbio'>
                                      <div className="pbio_head"><i class="fa fa-map-marker fa-lg" /> Primary Work Address</div>
                                      <li className='biogr_li'><b>Primary Work Address: </b>{this.state.PersonalInformation.address_1}, {this.state.PersonalInformation.address_2}</li>
                                      <li className='biogr_li'><b>City: </b>{this.state.PersonalInformation.city}</li>
                                      <li className='biogr_li'><b>State: </b>{this.state.PersonalInformation.state_id}</li>
                                      <li className='biogr_li'><b>Country: </b>{this.state.PersonalInformation.country_id} - {this.state.PersonalInformation.zip_code}</li>
                                    </p>
                                  </div>
                                  <div className='col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge'>
                                    <p className='pbio'>
                                      <div className="pbio_head"><i class="fa fa-link fa-lg" /> Personal Links</div>
                                      <li className='biogr_li'><b>Website URL: </b> <a href={this.state.PersonalInformation.website} target='_blank'> {this.state.PersonalInformation.website}</a></li>
                                      <li className='biogr_li'><b>LinkedIn Profile: </b><a href={this.state.PersonalInformation.linked_in_profile} target='_blank'> {this.state.PersonalInformation.linked_in_profile}</a></li>
                                      <li className='biogr_li'><b>Twitter Profile: </b><a href={this.state.PersonalInformation.twitter_handle} target='_blank'> {this.state.PersonalInformation.twitter_handle}</a></li>
                                    </p>
                                  </div>


                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge' >
                                    <div className="pbio_head"><i class="fa fa-briefcase fa-lg" /> Work Details</div>
                                    <div className='table-responsive mb-1'>
                                      <table className='table table-striped mb-2'>
                                        {/* <tbody className=''> */}
                                        <div >
                                          <BootstrapTable
                                            keyField='id'
                                            noDataIndication="Table is Empty"
                                            data={this.state.WorkDetails}
                                            columns={this.state.columnsWorkDetails}
                                            filter={filterFactory()}
                                            // striped
                                            hover
                                            condensed

                                            rowStyle={{ backgroundColor: '#fff' }}

                                          />
                                        </div>


                                        {/* </tbody> */}
                                      </table>
                                    </div>
                                    {/* </p> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {

                        (this.state.SummaryFlag == true) ? (


                          <div class="tab-pane container-xxxl mt-2" id="Summary">

                            <ul className="nav nav-pills pl-4">

                              <li className="nav-item pill-20">
                                <a className="nav-link nav-linkss active" onClick={this.HomePiechartFun} data-bs-toggle="pill" href="#home_sum">Home</a>
                              </li>
                              <li className="nav-item pill-21">
                                <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.EventPiechartFun} href="#event_sum">Events</a>
                              </li>
                              <li className="nav-item pill-22">
                                <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.PublicationPiechartFun} href="#publication_sum">Publications</a>
                              </li>
                              <li className="nav-item pill-23">
                                <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.ClinicaltrialPiechartFun} href="#ct_sum">Clinical Trials</a>
                              </li>
                              <li className="nav-item pill-24">
                                <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.AdboardPiechartFun} href="#advisory_sum">Advisory Boards</a>
                              </li>
                              <li className="nav-item pill-25">
                                <a className="nav-link nav-linkss" data-bs-toggle="pill" onClick={this.GuidelinePiechartFun} href="#guideline_sum">Guidelines</a>
                              </li>
                            </ul>

                            <div class="tbpnsd1">
                              {
                                (this.state.home_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="home_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >

                                        <div className="row center">

                                          <div className="col-9" style={{ marginBottom: '80px' }} >

                                            {/* <Chart
                                              chartType={'ColumnChart'}

                                              loader={<div>Loading Chart</div>}

                                              data={[
                                                ["", "Lifetime", { role: "annotation" }, this.state.year.latest_year.toString(), { role: "annotation" }, this.state.year.mid_year.toString(), { role: "annotation" }, this.state.year.last_year.toString(), { role: "annotation" }],
                                                ["Overall", this.state.Summaryoverall.Lifetime, this.state.Summaryoverall.Lifetime, this.state.Summaryoverall.latest_year, this.state.Summaryoverall.latest_year, this.state.Summaryoverall.mid_year, this.state.Summaryoverall.mid_year, this.state.Summaryoverall.last_year, this.state.Summaryoverall.last_year],
                                                ["Trial", this.state.Summaryclinicaltrialdetails.Lifetime, this.state.Summaryclinicaltrialdetails.Lifetime, this.state.Summaryclinicaltrialdetails.latest_year, this.state.Summaryclinicaltrialdetails.latest_year, this.state.Summaryclinicaltrialdetails.mid_year, this.state.Summaryclinicaltrialdetails.mid_year, this.state.Summaryclinicaltrialdetails.last_year, this.state.Summaryclinicaltrialdetails.last_year],
                                                ["Guideline", this.state.Summaryguidlines.Lifetime, this.state.Summaryguidlines.Lifetime, this.state.Summaryguidlines.latest_year, this.state.Summaryguidlines.latest_year, this.state.Summaryguidlines.mid_year, this.state.Summaryguidlines.mid_year, this.state.Summaryguidlines.last_year, this.state.Summaryguidlines.last_year],
                                                ["Event", this.state.Summaryeventdetails.Lifetime, this.state.Summaryeventdetails.Lifetime, this.state.Summaryeventdetails.latest_year, this.state.Summaryeventdetails.latest_year, this.state.Summaryeventdetails.mid_year, this.state.Summaryeventdetails.mid_year, this.state.Summaryeventdetails.last_year, this.state.Summaryeventdetails.last_year],
                                                ["Publication", this.state.Summarypublicationdetails.Lifetime, this.state.Summarypublicationdetails.Lifetime, this.state.Summarypublicationdetails.latest_year, this.state.Summarypublicationdetails.latest_year, this.state.Summarypublicationdetails.mid_year, this.state.Summarypublicationdetails.mid_year, this.state.Summarypublicationdetails.last_year, this.state.Summarypublicationdetails.last_year],
                                                ["Advisory", this.state.Summaryadvisoryboard.Lifetime, this.state.Summaryadvisoryboard.Lifetime, this.state.Summaryadvisoryboard.latest_year, this.state.Summaryadvisoryboard.latest_year, this.state.Summaryadvisoryboard.mid_year, this.state.Summaryadvisoryboard.mid_year, this.state.Summaryadvisoryboard.last_year, this.state.Summaryadvisoryboard.last_year],

                                              ]}

                                              options={EngagementSumChartOptions}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}

                                            /> */}



                                          </div>
                                          <div className="col-9" style={{ marginBottom: '80px' }}>
                                            {/* <Chart
                                              chartType={'ColumnChart'}
                                              data={
                                                [
                                                  ["", "Lifetime", { role: "annotation" }, this.state.year.latest_year.toString(), { role: "annotation" }, this.state.year.mid_year.toString(), { role: "annotation" }, this.state.year.last_year.toString(), { role: "annotation" }],

                                                  ["Clinical Trials", this.state.RecentTrendclinicaltrialdetails.Lifetime, this.state.RecentTrendclinicaltrialdetails.Lifetime, this.state.RecentTrendclinicaltrialdetails.latest_year, this.state.RecentTrendclinicaltrialdetails.latest_year, this.state.RecentTrendclinicaltrialdetails.mid_year, this.state.RecentTrendclinicaltrialdetails.mid_year, this.state.RecentTrendclinicaltrialdetails.last_year, this.state.RecentTrendclinicaltrialdetails.last_year],
                                                  ["Guidelines", this.state.RecentTrendguidlines.Lifetime, this.state.RecentTrendguidlines.Lifetime, this.state.RecentTrendguidlines.latest_year, this.state.RecentTrendguidlines.latest_year, this.state.RecentTrendguidlines.mid_year, this.state.RecentTrendguidlines.mid_year, this.state.RecentTrendguidlines.last_year, this.state.RecentTrendguidlines.last_year],
                                                  ["Events/Conferences", this.state.RecentTrendeventdetails.Lifetime, this.state.RecentTrendeventdetails.Lifetime, this.state.RecentTrendeventdetails.latest_year, this.state.RecentTrendeventdetails.latest_year, this.state.RecentTrendeventdetails.mid_year, this.state.RecentTrendeventdetails.mid_year, this.state.RecentTrendeventdetails.last_year, this.state.RecentTrendeventdetails.last_year],
                                                  ["Publication", this.state.RecentTrendpublicationdetails.Lifetime, this.state.RecentTrendpublicationdetails.Lifetime, this.state.RecentTrendpublicationdetails.latest_year, this.state.RecentTrendpublicationdetails.latest_year, this.state.RecentTrendpublicationdetails.mid_year, this.state.RecentTrendpublicationdetails.mid_year, this.state.RecentTrendpublicationdetails.last_year, this.state.RecentTrendpublicationdetails.last_year],
                                                  ["Advisory", this.state.RecentTrendadvisoryboard.Lifetime, this.state.RecentTrendadvisoryboard.Lifetime, this.state.RecentTrendadvisoryboard.latest_year, this.state.RecentTrendadvisoryboard.latest_year, this.state.RecentTrendadvisoryboard.mid_year, this.state.RecentTrendadvisoryboard.mid_year, this.state.RecentTrendadvisoryboard.last_year, this.state.RecentTrendadvisoryboard.last_year],
                                                ]}
                                              options={RecentTrendChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>






                                ) : (
                                  <div>

                                  </div>
                                )
                              }


                              {
                                (this.state.event_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="event_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >
                                        {/* <h6>Events/Conferences </h6> */}

                                        <div className="row center">
                                          <div className="col-9" style={{ marginBottom: '80px' }} >
{/* 
                                            <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [


                                                  ["", "Global", { role: "annotation" }, "Regional", { role: "annotation" }, "National", { role: "annotation" }, "Local", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.EventsGeographicGlobal.Lifetime, this.state.EventsGeographicGlobal.Lifetime + '%', this.state.EventsGeographicRegional.Lifetime, this.state.EventsGeographicRegional.Lifetime + '%', this.state.EventsGeographicNational.Lifetime, this.state.EventsGeographicNational.Lifetime + '%', this.state.EventsGeographicLocal.Lifetime, this.state.EventsGeographicLocal.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.EventsGeographicGlobal.latest_year, this.state.EventsGeographicGlobal.latest_year + '%', this.state.EventsGeographicRegional.latest_year, this.state.EventsGeographicRegional.latest_year + '%', this.state.EventsGeographicNational.latest_year, this.state.EventsGeographicNational.latest_year + '%', this.state.EventsGeographicLocal.latest_year, this.state.EventsGeographicLocal.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.EventsGeographicGlobal.mid_year, this.state.EventsGeographicGlobal.mid_year + '%', this.state.EventsGeographicRegional.mid_year, this.state.EventsGeographicRegional.mid_year + '%', this.state.EventsGeographicNational.mid_year, this.state.EventsGeographicNational.mid_year + '%', this.state.EventsGeographicLocal.mid_year, this.state.EventsGeographicLocal.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.EventsGeographicGlobal.last_year, this.state.EventsGeographicGlobal.last_year + '%', this.state.EventsGeographicRegional.last_year, this.state.EventsGeographicRegional.last_year + '%', this.state.EventsGeographicNational.last_year, this.state.EventsGeographicNational.last_year + '%', this.state.EventsGeographicLocal.last_year, this.state.EventsGeographicLocal.last_year + '%'],



                                                ]


                                              }
                                              options={EventConfrenceGeographicChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>

                                          <div className="col-9" style={{ marginBottom: '80px' }} >
{/* 
                                            <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "Event Chair", { role: "annotation" }, "Moderator/Chair", { role: "annotation" }, "Regular", { role: "annotation" }, "Keynote Speaker", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.EventsRoleChairperson.Lifetime, this.state.EventsRoleChairperson.Lifetime + '%', this.state.EventsRoleModerator.Lifetime, this.state.EventsRoleModerator.Lifetime + '%', this.state.EventsRoleRegular.Lifetime, this.state.EventsRoleRegular.Lifetime + '%', this.state.EventsRoleKeynote.Lifetime, this.state.EventsRoleKeynote.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.EventsRoleChairperson.latest_year, this.state.EventsRoleChairperson.latest_year + '%', this.state.EventsRoleModerator.latest_year, this.state.EventsRoleModerator.latest_year + '%', this.state.EventsRoleRegular.latest_year, this.state.EventsRoleRegular.latest_year + '%', this.state.EventsRoleKeynote.latest_year, this.state.EventsRoleKeynote.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.EventsRoleChairperson.mid_year, this.state.EventsRoleChairperson.mid_year + '%', this.state.EventsRoleModerator.mid_year, this.state.EventsRoleModerator.mid_year + '%', this.state.EventsRoleRegular.mid_year, this.state.EventsRoleRegular.mid_year + '%', this.state.EventsRoleKeynote.mid_year, this.state.EventsRoleKeynote.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.EventsRoleChairperson.last_year, this.state.EventsRoleChairperson.last_year + '%', this.state.EventsRoleModerator.last_year, this.state.EventsRoleModerator.last_year + '%', this.state.EventsRoleRegular.last_year, this.state.EventsRoleRegular.last_year + '%', this.state.EventsRoleKeynote.last_year, this.state.EventsRoleKeynote.last_year + '%'],

                                                ]
                                              }
                                              options={EventConfrenceRoleChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}




                                          </div>

                                        </div>


                                      </div>
                                    </div>
                                  </div>


                                ) : (
                                  <div>

                                  </div>
                                )
                              }


                              {
                                (this.state.publication_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="publication_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >
                                        {/* <h6>Publications</h6> */}
                                        <div className="row center">
                                          <div className="col-9" style={{ marginBottom: '80px' }}>

                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={


                                                [

                                                  ["", "Global", { role: "annotation" }, "Regional", { role: "annotation" }, "National", { role: "annotation" }, "Local", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.PublicationsGeographicGlobal.Lifetime, this.state.PublicationsGeographicGlobal.Lifetime + '%', this.state.PublicationsGeographicRegional.Lifetime, this.state.PublicationsGeographicRegional.Lifetime + '%', this.state.PublicationsGeographicNational.Lifetime, this.state.PublicationsGeographicNational.Lifetime + '%', this.state.PublicationsGeographicLocal.Lifetime, this.state.PublicationsGeographicLocal.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.PublicationsGeographicGlobal.latest_year, this.state.PublicationsGeographicGlobal.latest_year + '%', this.state.PublicationsGeographicRegional.latest_year, this.state.PublicationsGeographicRegional.latest_year + '%', this.state.PublicationsGeographicNational.latest_year, this.state.PublicationsGeographicNational.latest_year + '%', this.state.PublicationsGeographicLocal.latest_year, this.state.PublicationsGeographicLocal.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.PublicationsGeographicGlobal.mid_year, this.state.PublicationsGeographicGlobal.mid_year + '%', this.state.PublicationsGeographicRegional.mid_year, this.state.PublicationsGeographicRegional.mid_year + '%', this.state.PublicationsGeographicNational.mid_year, this.state.PublicationsGeographicNational.mid_year + '%', this.state.PublicationsGeographicLocal.mid_year, this.state.PublicationsGeographicLocal.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.PublicationsGeographicGlobal.last_year, this.state.PublicationsGeographicGlobal.last_year + '%', this.state.PublicationsGeographicRegional.last_year, this.state.PublicationsGeographicRegional.last_year + '%', this.state.PublicationsGeographicNational.last_year, this.state.PublicationsGeographicNational.last_year + '%', this.state.PublicationsGeographicLocal.last_year, this.state.PublicationsGeographicLocal.last_year + '%'],

                                                ]

                                              }
                                              options={PublicationGeographicChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>

                                          <div className="col-9" style={{ marginBottom: '80px' }}>

                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "First Author", { role: "annotation" }, "Second Author", { role: "annotation" }, "Other Author", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.PublicationsRoleFirstAuthor.Lifetime, this.state.PublicationsRoleFirstAuthor.Lifetime + '%', this.state.PublicationsRoleSecondAuthor.Lifetime, this.state.PublicationsRoleSecondAuthor.Lifetime + '%', this.state.PublicationsRoleLastAuthor.Lifetime, this.state.PublicationsRoleLastAuthor.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.PublicationsRoleFirstAuthor.latest_year, this.state.PublicationsRoleFirstAuthor.latest_year + '%', this.state.PublicationsRoleSecondAuthor.latest_year, this.state.PublicationsRoleSecondAuthor.latest_year + '%', this.state.PublicationsRoleLastAuthor.latest_year, this.state.PublicationsRoleLastAuthor.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.PublicationsRoleFirstAuthor.mid_year, this.state.PublicationsRoleFirstAuthor.mid_year + '%', this.state.PublicationsRoleSecondAuthor.mid_year, this.state.PublicationsRoleSecondAuthor.mid_year + '%', this.state.PublicationsRoleLastAuthor.mid_year, this.state.PublicationsRoleLastAuthor.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.PublicationsRoleFirstAuthor.last_year, this.state.PublicationsRoleFirstAuthor.last_year + '%', this.state.PublicationsRoleSecondAuthor.last_year, this.state.PublicationsRoleSecondAuthor.last_year + '%', this.state.PublicationsRoleLastAuthor.last_year, this.state.PublicationsRoleLastAuthor.last_year + '%'],
                                                ]

                                              }
                                              options={PublicationProminenceRoleChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}



                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                ) : (
                                  <div>

                                  </div>
                                )
                              }




                              {
                                (this.state.clinicaltrial_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="ct_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >
                                        {/* <h6>Clinical Trials</h6> */}

                                        <div className="row center">
                                          <div className="col-9" style={{ marginBottom: '80px' }} >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [

                                                  ["", "Global", { role: "annotation" }, "Regional", { role: "annotation" }, "National", { role: "annotation" }, "Local", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.ClinicalsGeographicGlobal.Lifetime, this.state.ClinicalsGeographicGlobal.Lifetime + '%', this.state.ClinicalsGeographicRegional.Lifetime, this.state.ClinicalsGeographicRegional.Lifetime + '%', this.state.ClinicalsGeographicNational.Lifetime, this.state.ClinicalsGeographicNational.Lifetime + '%', this.state.ClinicalsGeographicLocal.Lifetime, this.state.ClinicalsGeographicLocal.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.ClinicalsGeographicGlobal.latest_year, this.state.ClinicalsGeographicGlobal.latest_year + '%', this.state.ClinicalsGeographicRegional.latest_year, this.state.ClinicalsGeographicRegional.latest_year + '%', this.state.ClinicalsGeographicNational.latest_year, this.state.ClinicalsGeographicNational.latest_year + '%', this.state.ClinicalsGeographicLocal.latest_year, this.state.ClinicalsGeographicLocal.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.ClinicalsGeographicGlobal.mid_year, this.state.ClinicalsGeographicGlobal.mid_year + '%', this.state.ClinicalsGeographicRegional.mid_year, this.state.ClinicalsGeographicRegional.mid_year + '%', this.state.ClinicalsGeographicNational.mid_year, this.state.ClinicalsGeographicNational.mid_year + '%', this.state.ClinicalsGeographicLocal.mid_year, this.state.ClinicalsGeographicLocal.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.ClinicalsGeographicGlobal.last_year, this.state.ClinicalsGeographicGlobal.last_year + '%', this.state.ClinicalsGeographicRegional.last_year, this.state.ClinicalsGeographicRegional.last_year + '%', this.state.ClinicalsGeographicNational.last_year, this.state.ClinicalsGeographicNational.last_year + '%', this.state.ClinicalsGeographicLocal.last_year, this.state.ClinicalsGeographicLocal.last_year + '%'],
                                                ]

                                              }
                                              options={ClinicalTrialsGeographicChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                          <div className="col-9" style={{ marginBottom: '80px' }} >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={

                                                [
                                                  ["", "more than 50", { role: "annotation" }, "11-50", { role: "annotation" }, "1-10", { role: "annotation" }, "1", { role: "annotation" }],

                                                  [this.state.year.Lifetime.toString(), this.state.ClinicalsTrial50_more.Lifetime, this.state.ClinicalsTrial50_more.Lifetime + '%', this.state.ClinicalsTrial11_50.Lifetime, this.state.ClinicalsTrial11_50.Lifetime + '%', this.state.ClinicalsTrial1_10.Lifetime, this.state.ClinicalsTrial1_10.Lifetime + '%', this.state.ClinicalsTrial1.Lifetime, this.state.ClinicalsTrial1.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.ClinicalsTrial50_more.latest_year, this.state.ClinicalsTrial50_more.latest_year + '%', this.state.ClinicalsTrial11_50.latest_year, this.state.ClinicalsTrial11_50.latest_year + '%', this.state.ClinicalsTrial1_10.latest_year, this.state.ClinicalsTrial1_10.latest_year + '%', this.state.ClinicalsTrial1.latest_year, this.state.ClinicalsTrial1.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.ClinicalsTrial50_more.mid_year, this.state.ClinicalsTrial50_more.mid_year + '%', this.state.ClinicalsTrial11_50.mid_year, this.state.ClinicalsTrial11_50.mid_year + '%', this.state.ClinicalsTrial1_10.mid_year, this.state.ClinicalsTrial1_10.mid_year + '%', this.state.ClinicalsTrial1.mid_year, this.state.ClinicalsTrial1.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.ClinicalsTrial50_more.last_year, this.state.ClinicalsTrial50_more.last_year + '%', this.state.ClinicalsTrial11_50.last_year, this.state.ClinicalsTrial11_50.last_year + '%', this.state.ClinicalsTrial1_10.last_year, this.state.ClinicalsTrial1_10.last_year + '%', this.state.ClinicalsTrial1.last_year, this.state.ClinicalsTrial1.last_year + '%'],
                                                ]

                                              }
                                              options={ClinicalTrialsLocationChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>




                                          <div className="col-9" style={{ marginBottom: '80px' }} >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={

                                                [
                                                  ["", "Interventional", { role: "annotation" }, "Observational", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.ClinicalsTrialInterventional.Lifetime, this.state.ClinicalsTrialInterventional.Lifetime + '%', this.state.ClinicalsTrialObservational.Lifetime, this.state.ClinicalsTrialObservational.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.ClinicalsTrialInterventional.latest_year, this.state.ClinicalsTrialInterventional.latest_year + '%', this.state.ClinicalsTrialObservational.latest_year, this.state.ClinicalsTrialObservational.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.ClinicalsTrialInterventional.mid_year, this.state.ClinicalsTrialInterventional.mid_year + '%', this.state.ClinicalsTrialObservational.mid_year, this.state.ClinicalsTrialObservational.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.ClinicalsTrialInterventional.last_year, this.state.ClinicalsTrialInterventional.last_year + '%', this.state.ClinicalsTrialObservational.last_year, this.state.ClinicalsTrialObservational.last_year + '%'],
                                                ]

                                              }
                                              options={ClinicalTrialsTypeChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>

                                        </div>




                                      </div>
                                    </div>
                                  </div>


                                ) : (
                                  <div>

                                  </div>
                                )
                              }



                              {
                                (this.state.ad_board_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="advisory_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >
                                        {/* <h6>Advisory Board </h6> */}

                                        <div className="row center">
                                          <div className="col-9" style={{ marginBottom: '80px' }} >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "Global", { role: "annotation" }, "Regional", { role: "annotation" }, "National", { role: "annotation" }, "Local", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.AdvisoryGeographicGlobal.Lifetime, this.state.AdvisoryGeographicGlobal.Lifetime + '%', this.state.AdvisoryGeographicRegional.Lifetime, this.state.AdvisoryGeographicRegional.Lifetime + '%', this.state.AdvisoryGeographicNational.Lifetime, this.state.AdvisoryGeographicNational.Lifetime + '%', this.state.AdvisoryGeographicLocal.Lifetime, this.state.AdvisoryGeographicLocal.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.AdvisoryGeographicGlobal.latest_year, this.state.AdvisoryGeographicGlobal.latest_year + '%', this.state.AdvisoryGeographicRegional.latest_year, this.state.AdvisoryGeographicRegional.latest_year + '%', this.state.AdvisoryGeographicNational.latest_year, this.state.AdvisoryGeographicNational.latest_year + '%', this.state.AdvisoryGeographicLocal.latest_year, this.state.AdvisoryGeographicLocal.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.AdvisoryGeographicGlobal.mid_year, this.state.AdvisoryGeographicGlobal.mid_year + '%', this.state.AdvisoryGeographicRegional.mid_year, this.state.AdvisoryGeographicRegional.mid_year + '%', this.state.AdvisoryGeographicNational.mid_year, this.state.AdvisoryGeographicNational.mid_year + '%', this.state.AdvisoryGeographicLocal.mid_year, this.state.AdvisoryGeographicLocal.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.AdvisoryGeographicGlobal.last_year, this.state.AdvisoryGeographicGlobal.last_year + '%', this.state.AdvisoryGeographicRegional.last_year, this.state.AdvisoryGeographicRegional.last_year + '%', this.state.AdvisoryGeographicNational.last_year, this.state.AdvisoryGeographicNational.last_year + '%', this.state.AdvisoryGeographicLocal.last_year, this.state.AdvisoryGeographicLocal.last_year + '%'],
                                                ]
                                              }
                                              options={AdvisoryBoardGeographicChartOptions}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                          <div className="col-9" style={{ marginBottom: '80px' }}>
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={

                                                [
                                                  ["", "committee Member", { role: "annotation" }, "Chair", { role: "annotation" }, "Co Chair", { role: "annotation" }],

                                                  [this.state.year.Lifetime.toString(), this.state.AdvisoryRoleAdvisoryCommittee.Lifetime, this.state.AdvisoryRoleAdvisoryCommittee.Lifetime + '%', this.state.AdvisoryRolechair.Lifetime, this.state.AdvisoryRolechair.Lifetime + '%', this.state.AdvisoryRolecochair.Lifetime, this.state.AdvisoryRolecochair.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.AdvisoryRoleAdvisoryCommittee.latest_year, this.state.AdvisoryRoleAdvisoryCommittee.latest_year + '%', this.state.AdvisoryRolechair.latest_year, this.state.AdvisoryRolechair.latest_year + '%', this.state.AdvisoryRolecochair.latest_year, this.state.AdvisoryRolecochair.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.AdvisoryRoleAdvisoryCommittee.mid_year, this.state.AdvisoryRoleAdvisoryCommittee.mid_year + '%', this.state.AdvisoryRolechair.mid_year, this.state.AdvisoryRolechair.mid_year + '%', this.state.AdvisoryRolecochair.mid_year, this.state.AdvisoryRolecochair.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.AdvisoryRoleAdvisoryCommittee.last_year, this.state.AdvisoryRoleAdvisoryCommittee.last_year + '%', this.state.AdvisoryRolechair.last_year, this.state.AdvisoryRolechair.last_year + '%', this.state.AdvisoryRolecochair.last_year, this.state.AdvisoryRolecochair.last_year + '%'],
                                                ]
                                              }
                                              options={AdvisoryBoardRoleProminenceChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                        </div>



                                        <div className="row center">
                                          <div className="col-9" >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "Medtech Companies", { role: "annotation" }, "Pharma Companies", { role: "annotation" }, "Professional Association", { role: "annotation" }, "Goverment Institution", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.AdvisoryAffiliationMedtechCompanies.Lifetime, this.state.AdvisoryAffiliationMedtechCompanies.Lifetime + '%', this.state.AdvisoryAffiliationPharmaCompanies.Lifetime, this.state.AdvisoryAffiliationPharmaCompanies.Lifetime + '%', this.state.AdvisoryAffiliationProfessionalAssociations.Lifetime, this.state.AdvisoryAffiliationProfessionalAssociations.Lifetime + '%', this.state.AdvisoryAffiliationGovernmentInstitutions.Lifetime, this.state.AdvisoryAffiliationGovernmentInstitutions.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.AdvisoryAffiliationMedtechCompanies.latest_year, this.state.AdvisoryAffiliationMedtechCompanies.latest_year + '%', this.state.AdvisoryAffiliationPharmaCompanies.latest_year, this.state.AdvisoryAffiliationPharmaCompanies.latest_year + '%', this.state.AdvisoryAffiliationProfessionalAssociations.latest_year, this.state.AdvisoryAffiliationProfessionalAssociations.latest_year + '%', this.state.AdvisoryAffiliationGovernmentInstitutions.latest_year, this.state.AdvisoryAffiliationGovernmentInstitutions.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.AdvisoryAffiliationMedtechCompanies.mid_year, this.state.AdvisoryAffiliationMedtechCompanies.mid_year + '%', this.state.AdvisoryAffiliationPharmaCompanies.mid_year, this.state.AdvisoryAffiliationPharmaCompanies.mid_year + '%', this.state.AdvisoryAffiliationProfessionalAssociations.mid_year, this.state.AdvisoryAffiliationProfessionalAssociations.mid_year + '%', this.state.AdvisoryAffiliationGovernmentInstitutions.mid_year, this.state.AdvisoryAffiliationGovernmentInstitutions.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.AdvisoryAffiliationMedtechCompanies.last_year, this.state.AdvisoryAffiliationMedtechCompanies.last_year + '%', this.state.AdvisoryAffiliationPharmaCompanies.last_year, this.state.AdvisoryAffiliationPharmaCompanies.last_year + '%', this.state.AdvisoryAffiliationProfessionalAssociations.last_year, this.state.AdvisoryAffiliationProfessionalAssociations.last_year + '%', this.state.AdvisoryAffiliationGovernmentInstitutions.last_year, this.state.AdvisoryAffiliationGovernmentInstitutions.last_year + '%'],
                                                ]

                                              }
                                              options={AdvisoryBoardAffiliationTypeChartOptions}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                        </div>





                                      </div>
                                    </div>
                                  </div>


                                ) : (
                                  <div>

                                  </div>
                                )
                              }


                              {
                                (this.state.guideline_piechart_flag == true) ? (

                                  <div class="tab-pane container-xxl mt-2 active" id="guideline_sum">
                                    <div className='card scrl_cntnts'>
                                      <div className='card-body' >
                                        {/* <h6>Guidelines</h6> */}



                                        <div className="row center" style={{ marginBottom: '80px' }}>
                                          <div className="col-9" >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={

                                                [
                                                  ["", "Global", { role: "annotation" }, "Regional", { role: "annotation" }, "National", { role: "annotation" }, "Local", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.GuidelinesGeographicGlobal.Lifetime, this.state.GuidelinesGeographicGlobal.Lifetime + '%', this.state.GuidelinesGeographicRegional.Lifetime, this.state.GuidelinesGeographicRegional.Lifetime + '%', this.state.GuidelinesGeographicNational.Lifetime, this.state.GuidelinesGeographicNational.Lifetime + '%', this.state.GuidelinesGeographicLocal.Lifetime, this.state.GuidelinesGeographicLocal.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.GuidelinesGeographicGlobal.latest_year, this.state.GuidelinesGeographicGlobal.latest_year + '%', this.state.GuidelinesGeographicRegional.latest_year, this.state.GuidelinesGeographicRegional.latest_year + '%', this.state.GuidelinesGeographicNational.latest_year, this.state.GuidelinesGeographicNational.latest_year + '%', this.state.GuidelinesGeographicLocal.latest_year, this.state.GuidelinesGeographicLocal.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.GuidelinesGeographicGlobal.mid_year, this.state.GuidelinesGeographicGlobal.mid_year + '%', this.state.GuidelinesGeographicRegional.mid_year, this.state.GuidelinesGeographicRegional.mid_year + '%', this.state.GuidelinesGeographicNational.mid_year, this.state.GuidelinesGeographicNational.mid_year + '%', this.state.GuidelinesGeographicLocal.mid_year, this.state.GuidelinesGeographicLocal.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.GuidelinesGeographicGlobal.last_year, this.state.GuidelinesGeographicGlobal.last_year + '%', this.state.GuidelinesGeographicRegional.last_year, this.state.GuidelinesGeographicRegional.last_year + '%', this.state.GuidelinesGeographicNational.last_year, this.state.GuidelinesGeographicNational.last_year + '%', this.state.GuidelinesGeographicLocal.last_year, this.state.GuidelinesGeographicLocal.last_year + '%'],
                                                ]
                                              }
                                              options={GuidelinesGeographicChartOptions}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                          <div className="col-9" >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "Author", { role: "annotation" }, "Committee Member", { role: "annotation" }, "Committee Chair", { role: "annotation" }],

                                                  [this.state.year.Lifetime.toString(), this.state.GuidelinesRoleAuthor.Lifetime, this.state.GuidelinesRoleAuthor.Lifetime + '%', this.state.GuidelinesRoleCommitteeMember.Lifetime, this.state.GuidelinesRoleCommitteeMember.Lifetime + '%', this.state.GuidelinesRoleCommitteeChair.Lifetime, this.state.GuidelinesRoleCommitteeChair.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.GuidelinesRoleAuthor.latest_year, this.state.GuidelinesRoleAuthor.latest_year + '%', this.state.GuidelinesRoleCommitteeMember.latest_year, this.state.GuidelinesRoleCommitteeMember.latest_year + '%', this.state.GuidelinesRoleCommitteeChair.latest_year, this.state.GuidelinesRoleCommitteeChair.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.GuidelinesRoleAuthor.mid_year, this.state.GuidelinesRoleAuthor.mid_year + '%', this.state.GuidelinesRoleCommitteeMember.mid_year, this.state.GuidelinesRoleCommitteeMember.mid_year + '%', this.state.GuidelinesRoleCommitteeChair.mid_year, this.state.GuidelinesRoleCommitteeChair.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.GuidelinesRoleAuthor.last_year, this.state.GuidelinesRoleAuthor.last_year + '%', this.state.GuidelinesRoleCommitteeMember.last_year, this.state.GuidelinesRoleCommitteeMember.last_year + '%', this.state.GuidelinesRoleCommitteeChair.last_year, this.state.GuidelinesRoleCommitteeChair.last_year + '%'],
                                                ]
                                              }
                                              options={GuidelinesRoleProminenceChartOptions}
                                              //view={view}

                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}

                                          </div>
                                        </div>



                                        <div className="row center">
                                          <div className="col-9" >
                                            {/* <Chart
                                              //chartType="ColumnChart"
                                              chartType={'ColumnChart'}
                                              // loader={<div>Loading Data...</div>}

                                              data={
                                                [
                                                  ["", "Academic Hospital", { role: "annotation" }, "Professional Associations", { role: "annotation" }, "Government Institutions", { role: "annotation" }],
                                                  [this.state.year.Lifetime.toString(), this.state.GuidelinesAffiliationAcademicHospital.Lifetime, this.state.GuidelinesAffiliationAcademicHospital.Lifetime + '%', this.state.GuidelinesAffiliationProfessionalAssociations.Lifetime, this.state.GuidelinesAffiliationProfessionalAssociations.Lifetime + '%', this.state.GuidelinesAffiliationGovernmentInstitutions.Lifetime, this.state.GuidelinesAffiliationGovernmentInstitutions.Lifetime + '%'],
                                                  [this.state.year.latest_year.toString(), this.state.GuidelinesAffiliationAcademicHospital.latest_year, this.state.GuidelinesAffiliationAcademicHospital.latest_year + '%', this.state.GuidelinesAffiliationProfessionalAssociations.latest_year, this.state.GuidelinesAffiliationProfessionalAssociations.latest_year + '%', this.state.GuidelinesAffiliationGovernmentInstitutions.latest_year, this.state.GuidelinesAffiliationGovernmentInstitutions.latest_year + '%'],
                                                  [this.state.year.mid_year.toString(), this.state.GuidelinesAffiliationAcademicHospital.mid_year, this.state.GuidelinesAffiliationAcademicHospital.mid_year + '%', this.state.GuidelinesAffiliationProfessionalAssociations.mid_year, this.state.GuidelinesAffiliationProfessionalAssociations.mid_year + '%', this.state.GuidelinesAffiliationGovernmentInstitutions.mid_year, this.state.GuidelinesAffiliationGovernmentInstitutions.mid_year + '%'],
                                                  [this.state.year.last_year.toString(), this.state.GuidelinesAffiliationAcademicHospital.last_year, this.state.GuidelinesAffiliationAcademicHospital.last_year + '%', this.state.GuidelinesAffiliationProfessionalAssociations.last_year, this.state.GuidelinesAffiliationProfessionalAssociations.last_year + '%', this.state.GuidelinesAffiliationGovernmentInstitutions.last_year, this.state.GuidelinesAffiliationGovernmentInstitutions.last_year + '%'],


                                                ]
                                              }
                                              options={GuidelinesRoleProminenceChartOptions}
                                              width={"100%"}
                                              height={"350px"}
                                              rootProps={{ 'data-testid': '1' }}
                                            /> */}


                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                ) : (
                                  <div>

                                  </div>
                                )
                              }


                            </div>



                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }

                      {/* <a id="advisory-board"></a> */}


                      {
                        (this.state.BiographyFlag == true) ? (


                          <div class="tab-pane container-xxl mt-2" id="Biography">
                            <div className='card mb-3 scrl_cntnt'>
                              <div className='card-body'>
                                <h6><b>Biography Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                                    <p><b>Citizenship:</b> {this.state.Biography.citizenship}</p>
                                  </div>
                                  <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                                    <p><b>Language Spoken:</b> {this.state.Biography.language_spoken}</p>
                                  </div>
                                  <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                                    <p><b>Linkedin Followers:</b> {this.state.Biography.linkedin_followers}</p>
                                  </div>
                                  <div className='col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge'>
                                    <p><b>Twitter Followers:</b> {this.state.Biography.twitter_followers}</p>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                                    <p><b>Areas of Interest:</b>{this.state.Biography.areas_of_interest}</p>
                                  </div>
                                  {/* <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                          <p><b>Expertise:</b> {this.state.PersonalInformation.expertise}</p>
                        </div> */}

                                </div>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                                    <p className='pbio'><b>Biography:</b>
                                      <view style={{ whiteSpace: "break-spaces" }}>
                                        {this.state.Biography.biography}
                                      </view>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }



                      {/* Professional membership */}
                      {
                        (this.state.ProfessionalMembershipFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Professionaldtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Professional Membership Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            // responsive  
                                            // scrollX   
                                            // wrapperClasses="table-responsive"
                                            // wrapperClasses ="tablebootstrap" 
                                            // style={{width:'200%'}}   
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.ProfessionalMemberships}
                                            columns={this.state.columnsProfessionalMemberships}
                                            filter={filterFactory()}
                                            //striped
                                            hover
                                            condensed

                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>
                          </div>
                        )
                      }


                      {
                        (this.state.EducationFlag == true) ? (
                          <div class="tab-pane container-xxl mt-2" id="Educationdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Education Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Education}
                                            columns={this.state.columnsEducation}
                                            filter={filterFactory()}
                                            // striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />

                                        </div>

                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }



                      {
                        (this.state.AdvisoryBoardFlag == true) ? (
                          <div class="tab-pane container-xxl mt-2" id="Advisorydtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Advisory Board Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.AdvisoryBoard}
                                            columns={this.state.columnsAdvisoryBord}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }

                      {
                        (this.state.AwardsAndHonoursFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Awardsdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Awards and Honours Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.AwardsHonours}
                                            columns={this.state.columnsAwardsHonours}
                                            // striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>

                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.BooksAndMonographFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Booksdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Books and Monographs</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>

                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.BooksMonographs}
                                            columns={this.state.columnsBooksMonographs}
                                            //  striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )
                      }



                      {
                        (this.state.CommiteesFlag == true) ? (
                          <div class="tab-pane container-xxl mt-2" id="Committeedtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Committee Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>

                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Commitees}
                                            columns={this.state.columnsCommitees}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />

                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.ClinicalTrialDetailsFlag == true) ? (
                          <div class="tab-pane container-xxl mt-2" id="ctdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Clinical Trial Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.ClinicalTrialDetails}
                                            columns={this.state.columnsClinicalTrialDetails}
                                            // striped
                                            hover
                                            rowStyle={{ backgroundColor: '#fff' }}
                                            condensed
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.EventsDetailsFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Eventdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Event Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>

                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.EventsDetails}
                                            columns={this.state.columnsEventsDetails}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.GrantsFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Grantsdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Grants Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Grants}
                                            columns={this.state.columnsGrants}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.GuidelinesFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Guidelinesdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Guidelines Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Guidelines}
                                            columns={this.state.columnsGuidelines}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.HospitalAffiliationsFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Hospitaldtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Hospital Affiliation Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.HospitalAffiliations}
                                            columns={this.state.columnsHospitalAffiliations}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />

                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }



                      {
                        (this.state.InteractionsFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Interactionsdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Interactions Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Interactions}
                                            columns={this.state.columnsInteractions}
                                            //  striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )
                      }

                      {
                        (this.state.PressItemFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Pressdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Press Item Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.PressItem}
                                            columns={this.state.columnsPressItem}
                                            //striped

                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }

                      {
                        (this.state.PresentationsFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Presentationsdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Presentation Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.Presentation}
                                            columns={this.state.columnsPresentation}
                                            // striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>

                          </div>
                        )
                      }



                      {
                        (this.state.PublicationDetailsFlag == true) ? (




                          <div class="tab-pane container-xxl mt-2" id="Publicationsdtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Publication Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.PublicationDetails}
                                            columns={this.state.columnsPublicationDetails}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        ) : (
                          <div>

                          </div>
                        )
                      }


                      {
                        (this.state.SunshinePaymentFlag == true) ? (

                          <div class="tab-pane container-xxl mt-2" id="Sunshinedtl">
                            <div className='card mb-3 scrl_cntntss'>
                              <div className='card-body'>
                                <h6><b>Sunshine Payment Details</b></h6><hr></hr>
                                <div className='row'>
                                  <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                    <div className='table-responsive table_srls'>
                                      <table className='table table-striped mb-2'>
                                        <div className='bg-light'>
                                          <BootstrapTable
                                            noDataIndication="Table is Empty"
                                            keyField='id'
                                            data={this.state.SunshinePayment}
                                            columns={this.state.columnsSunshinePayment}
                                            //striped
                                            hover
                                            condensed
                                            rowStyle={{ backgroundColor: '#fff' }}
                                          />
                                        </div>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        ) : (
                          <div>

                          </div>
                        )
                      }

                      {/* 
                <div class="tab-pane container-xxl mt-2" id="">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b></b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <thead className='bg-light'>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className='table_srl'>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}



                      <Modal isOpen={this.state.NewQuoteModal} toggle={this.toggleinfoBuyModal.bind(this)} centered>
                        <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                          cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                          style={{ borderBottom: '0px' }}>
                          <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Get a quote</h3>
                        </ModalHeader>

                        {/* <ModalHeader toggle={this.toggleinfoBuyModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' , backgroundColor:'#ccc' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '20px' }} id="login-tit">Get a quote</h3>
                                        </ModalHeader> */}

                        <ModalBody style={{ padding: '0px 70px 0px 70px' }}>


                          <Quoteform />

                        </ModalBody>

                      </Modal>




                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>




        {/* <footer class="footer_not">

                                  <div class="cetas-footer">
                                      <div class="container">
                                          <p>All rights reserved. www.cetashealthcare.com</p>
                                      </div>
                                  </div>
            </footer>  */}


        <Pagefooter />
      </div>

    );



  }
}

export default ClientKolProfile;