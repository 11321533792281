import React from 'react';
import {BrowserRouter, Route,Switch} from 'react-router-dom';

import Home from './home';
import KOLidentification from './kolidentification';
import KOLEngagement from './kolEngagement';
import About from './about';
import Pricing from './pricing';
import Contact from './contact';
import Resources from './blogs';
import BlogsDetails from './blogdetails';
// import CaseStudyDetails from './casestudydetails';
// import CaseStudy from './casestudy';
import KOLCompliant from './compliant';
import pdftest from './pdftest';

import Login from './Login/Login';
import Signup from './Login/Signup';
import Forgotpassword from './Login/Forgotpass';
import Resetpassword from './Login/Resetpassword';

import ClientViewBio from './Client/ClientViewBio';
import ClientViewProfile from './Client/ClientViewProfile';

import PClientViewKolList from './PClient/ClientViewKolList';
import PClientKolProfile from './PClient/ClientKolProfile';
import PProfiletypedetails from './Profiletypedetails';

import ClientViewProject from './Client/ClientViewProject';
import ClientViewKolList from './Client/ClientViewKolList';
import ClientKolProfile from './Client/ClientKolProfile';
// import SearchInCetasDb from './Client/SearchInCetasDb';
// import ClientViewSearchKolList from './Client/ClientViewSearchKolList'


// componentDidMount() {
//     const { history } = this.props;

//     window.addEventListener("popstate", () => {
//       history.go(1);
//     });
// };

const Routes = () => (
     
      <BrowserRouter basename="/">

     {/* <BrowserRouter basename="/cetas"> */}
    
    <Switch>
    <Route exact path='/' component={Home}/>
    <Route exact path='/kol identification' component={KOLidentification}/>
    <Route exact path='/kol engagement' component={KOLEngagement}/>
    <Route exact path='/about us' component={About}/>
    <Route exact path='/pricing' component={Pricing}/>
    <Route exact path='/blogs' component={Resources}/>
    <Route exact path='/blog details' component={BlogsDetails}/> 
    {/* <Route exact path='/casestudy' component={CaseStudy}/>
    <Route exact path='/casestudydetails' component={CaseStudyDetails}/> */}
    <Route exact path='/contact us' component={Contact}/>
    <Route exact path='/compliant' component={KOLCompliant}/>

    <Route  path='/pdftest' component={pdftest} />
    
    <Route  path='/user login' component={Login} />
   
    <Route  path='/home' component={Home} />
    <Route  path='/create new account' component={Signup} />

    <Route  path='/reset password' component={Forgotpassword} />
    <Route  path='/updateoc=eynewy5=eypassword' component={Resetpassword} />

    <Route  path='/KOL Directory' component={PClientViewKolList} />
    <Route  path='/Sample Profile' component={PClientKolProfile} />
    {/* <Route  path='/Profiletypedetails' component={PProfiletypedetails} /> */}

    
    <Route  path='/My Dashboard' component={ClientViewProject} />
    <Route  path='/My Database' component={ClientViewKolList} />
    <Route  path='/Bio Export' component={ClientViewBio} />
    <Route  path='/Profile Export' component={ClientViewProfile} />
    <Route  path='/Profile View' component={ClientKolProfile} />
    {/*<Route path='/SearchInCetasDb' component={SearchInCetasDb} />
    <Route path='/ClientViewSearchKolList' component={ClientViewSearchKolList} />*/}

    
    </Switch>
    </BrowserRouter>
   
);
export default Routes;