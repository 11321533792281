import React, { Component, useRef, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import '../landingpage.scss';
import LoginHeader from './Loginheader';
import { AutoplayButton } from 'hero-slider';
import { bool, boolean } from 'yup';
import * as Yup from 'yup';


export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

const bcrypt=require('bcryptjs');


class Signupinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectSignup: false,
      redirectSignin: false,
      redirecthome: false,
      redirectAdmin: false,
      redirectAdministrator: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,

      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,


      kdidentificationhidediv: false,
      kdprofilinghidediv: false,
      kdinteracthidediv: false,


      loginModal: false,
      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: '',
      resercher_name: '',

      termconditionAgree: false,


      Email_Duplicate_status: '',
      // Manager info
      manager_id: '',
      manager_name: '',

      // Employee info
      employee_id: '',
      employee_name: '',

      // For validations
      UsernameErr: '',
      UseremailErr: '',
      CompanyNameErr: '',
      PasswordErr: '',
      confirmpasswordErr: '',
      confirmpassword: '',
      termconditionErr: '',

      SelectRoleErr: '',
      wrngUsPsErr: '',

      clientUsernameErr: '',
      clientPasswordErr: '',

      administratorUsernameErr: '',
      administratorPasswordErr: '',

      visitorUsernameErr: '',
      visitorPasswordErr: '',

      kolProfileUsernameErr: '',
      kolProfilePasswordErr: '',
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      signupData: {
        client_name: '',
        email_id: '',
        company_nm: '',
        password: '',
        signup_method: '',
        status: 'New',
        //email_verified:'pending',
        // loginType: 'pre_client',
      },

      ClientLoginData: {
        login_id: '',
        password: '',
        login_type: 'client',
      },
      teamLoginIdForTerms: '',
      //Administrator
      AdministratorLoginData: {
        login_id: '',
        password: '',
        login_type: 'administrator',
      },
      //visitor
      VisitorLoginData: {
        login_id: '',
        password: '',
        login_type: 'visitor',
      },

      KolProfileLoginData: {
        username: '',
        password: '',
        // kol_login_role: '',
      },
      kolLoginIdForTerms: '',

      contact_us: {
        first_name: '',
        last_name: '',
        organization: '',
        email_id: '',
        phone_no: '',
        country: '',
        learn_qpharma: '',
        representative_contact_me: '',
        qpharmas_products: '',
        technical_support_issue: '',
        need_of_assistance: '',
        receive_qpharma_news: '',
        describe_issue_question: '',
      },
      cuFirstNameErr: '',
      cuLastNameErr: '',
      cuOrganizaErr: '',
      cuEmailErr: '',
      cuPhoneErr: '',
      cuCountryErr: '',

      new_guru: {
        first_name: '',
        last_name: '',
        mobile_no: '',
        email_id: '',
        speciality: '',
        sub_speciality: '',
        procedure_type: '',
        country: '',
        state: '',
        current_place_of_work: '',
        department: '',
        linkedin_link: '',
        additional_info: '',
      },
      ngFirstNameErr: '',
      ngLastNameErr: '',
      ngEmailErr: '',
      cuPhoneErr: '',
      ngCountryErr: '',
      ngStateErr: '',

      new_client: {
        first_name: '',
        last_name: '',
        email_id: '',
        mobile_no: '',
        msg: '',
      },
      ncFirstNameErr: '',
      ncLastNameErr: '',
      ncEmailErr: '',
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: '',
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: '',
        role: '',
      }
    };

    
  }


  componentDidMount = async () => {
    console.log('in componentDidMount');

    const myInput = document.getElementById('password');
    const letter = document.getElementById('pass-lower');
    const capital = document.getElementById('pass-upper');
    const number = document.getElementById('pass-number');
    const length = document.getElementById('pass-length');
  
    myInput.addEventListener('focus', function() {
      document.getElementById('password-contain').style.display = 'block';
    });
  
    myInput.addEventListener('blur', function() {
      document.getElementById('password-contain').style.display = 'none';
    });
  
    myInput.addEventListener('keyup', function() {
      console.log('here in pass');
      const containsLowercase = myInput.value.match(/[a-z]/g);
      const containsUppercase = myInput.value.match(/[A-Z]/g);
      const containsNumber = myInput.value.match(/[0-9]/g);
      const isLengthValid = myInput.value.length >= 8;
  
      length.classList.toggle('valid', isLengthValid);
      length.classList.toggle('invalid', !isLengthValid);
  
      letter.classList.toggle('valid', containsLowercase);
      letter.classList.toggle('invalid', !containsLowercase);
  
      capital.classList.toggle('valid', containsUppercase);
      capital.classList.toggle('invalid', !containsUppercase);
  
      number.classList.toggle('valid', containsNumber);
      number.classList.toggle('invalid', !containsNumber);
    });

    let getSession_quotaiton_id = sessionStorage.getItem('quotation_id')

    if (getSession_quotaiton_id !== null) {
      let { signupData } = this.state;
      signupData.signup_method = 'quotation';
      signupData.quotation_id = getSession_quotaiton_id;
      this.setState({ signupData });

    }
    else {
      let { signupData } = this.state;
      signupData.signup_method = 'Portal';
      signupData.quotation_id = '';
      this.setState({ signupData });

    }

  }



  //login = toggle validate and login
  toggleLoginModal() {
    //console.log("loginclicked")
    this.setState({
      loginModal: !this.state.loginModal,
      UsernameErr: '',
      PasswordErr: '',
      SelectRoleErr: '',
      MySProfilePropover: false,
    });
  }

  toggleWithoutidModal() {


    this.setState({ redirectSignup: true })


  }

  redirectSignin() {
    this.setState({ redirectSignin: true })

  }



  validate = () => {
    let UsernameErr = '';
    let UseremailErr = '';
    let CompanyNameErr = '';
    let PasswordErr = '';
    let confirmpasswordErr = '';
    let termconditionErr = '';

    if (!this.state.signupData.client_name) {
      UsernameErr = 'Please Enter Full Name';
    }
    if (!this.state.signupData.email_id) {
      UseremailErr = 'Please Enter Email id';
    }

    if (!this.state.signupData.company_nm) {
      CompanyNameErr = 'Please Enter Company Name';
    }

    if (!this.state.signupData.password) {
      PasswordErr = 'Please Enter Password';
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(this.state.signupData.password)) {
      PasswordErr = 'Password must be at least 8 characters long and contain at least one number, one uppercase, and one lowercase letter';
    }

    if (!this.state.confirmpassword) {
      confirmpasswordErr = 'Please Enter Confirm Password';
    }

    if (!this.state.termconditionAgree) {
      termconditionErr = 'Please Accept term and condition';
    }

    if (UsernameErr || UseremailErr || CompanyNameErr || PasswordErr || confirmpasswordErr || termconditionErr) {
      this.setState({ UsernameErr, PasswordErr, UseremailErr, CompanyNameErr, confirmpasswordErr, termconditionErr });
      return false;
    }
    return true;
  }
 
  passmatchfunc() {
    if (this.state.signupData.password != this.state.confirmpassword) {
      this.setState({
        confirmpasswordErr: "Passwords don't match",
      })
    }
    else {
      this.setState({
        confirmpasswordErr: '',
        confirmpasswordErr: "Passwords match",
        color: 'green'
      })
    }
  }

  Validate_Duplicate_Email() {

    this.setState({ ShowBar: true, })

    const url = global.websiteUrl + '/service/KOL_landing/check_duplicate_signup';
    let formData = new FormData();
    formData.append('Signup_Info', JSON.stringify(this.state.signupData));

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));

        this.setState({ ShowBar: false, })
        // alert(lgstatus);
        if (lgstatus == false) {
          this.signup_user();
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: 'Emails Already exits pls change new email',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })

          this.setState({
            UseremailErr: 'Change New Email',
            confirmpasswordErr: '',
            UsernameErr: '',
            CompanyNameErr: '',
            PasswordErr: '',
            termconditionErr: '',
          })
        }

      })
      .catch(error => {
        console.log(error);
      });



  }

  // Verified_Duplicate_Email = () =>
  // {


  //   let eem=this.state.signupData.email_id;

  //   if(eem.includes('@') && eem.includes('.'))
  //   {
  //               this.setState({
  //                 ShowBar: true,
  //               })

  //                 const url = global.websiteUrl+'/service/KOL_landing/check_duplicate_signup';
  //                 let formData = new FormData();
  //                 formData.append('Signup_Info',JSON.stringify(this.state.signupData));

  //                 const config = {
  //                   //   headers: { 'content-type': 'multipart/form-data' }
  //                 }
  //                 axios.post(url, formData, config)
  //                   .then(response => {
  //                     console.log(response);
  //                     var lgdata = response.data.signup_List;
  //                     console.log("lgdata===========", JSON.stringify(lgdata));
  //                     var lgstatus = response.data.status;
  //                     console.log("lgstatus", JSON.stringify(lgstatus));
  //                     console.log("response message2", response.data.message)

  //                     this.setState({
  //                       ShowBar: false,
  //                     })
  //                         if (lgstatus == true) 
  //                         {
  //                                 this.setState({
  //                                   Email_Duplicate_status:true,
  //                                   UseremailErr: 'Email exist pls change new',
  //                                   confirmpasswordErr:'',
  //                                   UsernameErr:'',
  //                                   CompanyNameErr:'',
  //                                   PasswordErr:'',
  //                                   termconditionErr:'',                  
  //                                 })



  //                         }
  //                         else
  //                         {

  //                             this.setState({
  //                               Email_Duplicate_status:false,
  //                               UseremailErr: '',                                  
  //                                 confirmpasswordErr:'',
  //                                 UsernameErr:'',
  //                                 CompanyNameErr:'',
  //                                 PasswordErr:'',
  //                                 termconditionErr:'',

  //                                             })

  //                         }

  //                   })
  //                   .catch(error => {
  //                     console.log(error);
  //                   });


  //   }
  //   else
  //   {
  //     // Email Envalid
  //     this.setState({
  //     Email_Duplicate_status:true,
  //     UseremailErr: 'Invalid email',
  //     confirmpasswordErr:'',
  //     UsernameErr:'',
  //     CompanyNameErr:'',
  //     PasswordErr:'',   
  //     termconditionErr:'',
  //   })



  //   }

  //   return this.state.Email_Duplicate_status;
  // }

  checkboxHandler = (event) => {
    this.setState({ termconditionAgree: !this.state.termconditionAgree })
  }

  UserSignupfun() {


    const isValid = this.validate();
    // const isValid ="true";    
    if (isValid) {
      let eem = this.state.signupData.email_id;
      if (eem.includes('@') && eem.includes('.')) {

        if (this.state.signupData.password != this.state.confirmpassword) {
          this.setState({
            confirmpasswordErr: "Passwords don't match",
            UsernameErr: '',
            UseremailErr: '',
            CompanyNameErr: '',
            PasswordErr: '',
            termconditionErr: ''
          })

        } else {
          this.Validate_Duplicate_Email();
        }

      }
      else {
        // Email Envalid
        this.setState({
          UseremailErr: 'Invalid email',
          confirmpasswordErr: '',
          UsernameErr: '',
          CompanyNameErr: '',
          PasswordErr: '',
          termconditionErr: '',
        })



      }
    }

  }

  signup_user = async () => {

    this.setState({
      ShowBar: true,
    })
    const hashedPassword = await bcrypt.hash(this.state.signupData.password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
   
    let { signupData } = this.state;
    signupData.password = hashedPassword;
    this.setState({ signupData });
   //console.log("hashedPassword",this.state.signupData);
   
    const url = global.websiteUrl + '/service/KOL_landing/signup_user';
    let formData = new FormData();

    formData.append('Signup_Info', JSON.stringify(this.state.signupData));
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));
        console.log("response message2", response.data.message)

        this.setState({
          ShowBar: false,
        })
        //  alert(response.data.status);
        if (response.data.status == true) {
          Swal.fire({
            icon: 'success',
            //text: response.data.message,
            text: 'We are now working to create your account, and will be sending out the details via email in next 24 hours.',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'

          })

          sessionStorage.removeItem('quotation_id', '');
        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }

        this.setState({ redirecthome: true })


      })
      .catch(error => {
        console.log(error);
      });




  }

  makespaceondivkdidentification() {

    this.setState({

      hidedivkdprofiling: false,
      hidedivkdinteract: false,
      hidedivkdidentification: true,



    });

  }

  makespaceondivkdprofiling() {

    this.setState({

      hidedivkdidentification: false,
      hidedivkdinteract: false,
      hidedivkdprofiling: true,

    });

  }

  makespaceondivkdinteract() {

    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: true,

    });

  }

  makespaceondivhome() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: false,

    });
  }

  render() {

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    if (this.state.redirectSignin) {
      return (<Redirect to={'/Login'} />)
    }

    if (this.state.redirecthome) {
      return (<Redirect to={'/home'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    if (this.state.redirectPClientDashboard) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }


    return (


      <body id="landing-body vh-100" style={{ backgroundColor: '#ccc' }}>


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}

        {/* Call the function to add a library */}
        {/* {AddLibrary('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js')} */}
        {/* 
  {AddLibrary('./js/jquery.min.js')}
   {AddLibrary('./js/bootstrap.bundle.min.js')}
  {AddLibrary('./js/owl.carousel.min.js')} */}
        {AddLibrary('./js/app.js')}



        {/* <div style={{ backgroundColor:'#fff', height:'590px'}}>         */}

        <div style={{ backgroundColor: '#fff', height: '480px' }}>

          <section id="services" className="text-center" >

            <div className="container" >
              <div className="row">
                <div className="col-12">
                  <div className="login" style={{ width: '100%', marginTop: '-100px' }} >

                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }} >Full Name <span class="mandatory">*</span></label>
                      <Input id="username" placeholder="Enter Full Name" tabindex='1' class="input"
                        type="email"
                        value={this.state.signupData.client_name} onChange={(e) => {
                          let { signupData } = this.state;
                          signupData.client_name = e.target.value;
                          this.setState({ signupData });
                          // this.setState({ loginData, teamLoginIdForTerms: e.target.value });
                        }}
                        onKeyUp={this.validate}
                      />
                    </div>
                    <div className="error"> {this.state.UsernameErr}</div>

                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }}>Email <span class="mandatory">*</span></label>
                      <Input id="email_id" placeholder="Enter email address" tabindex='2' class="input"
                        type="email"
                        value={this.state.signupData.email_id}
                        onChange={(e) => {
                          let { signupData } = this.state;
                          signupData.email_id = e.target.value;
                          this.setState({ signupData });
                        }}
                      />
                    </div>
                    <div className="error"> {this.state.UseremailErr}</div>

                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }}>Company Name <span class="mandatory">*</span></label>
                      <Input id="companyname" placeholder="Enter Company Name" tabindex='3' class="input"
                        type="email"
                        value={this.state.signupData.company_nm} onChange={(e) => {
                          let { signupData } = this.state;
                          signupData.company_nm = e.target.value;
                          this.setState({ signupData });
                        }}
                      />
                    </div>
                    <div className="error"> {this.state.CompanyNameErr}</div>


                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }} >Password<span class="mandatory">*</span></label>
                      <Input id="password" placeholder="Password" class="input" tabindex='4'
                        type="password"
                        value={this.state.signupData.password} onChange={(e) => {
                          let { signupData } = this.state;
                          signupData.password = e.target.value;
                          this.setState({ signupData });
                        }}
                      />
                    </div>
                    <div id="password-contain" className="my-2 text-start">
                        <h5 className="fs-13">Password must contain:</h5>
                        <p id="pass-length" className="invalid fs-10 mb-1">Minimum <b>8 characters</b></p>
                        <p id="pass-lower" className="invalid fs-10 mb-1">At <b>lowercase</b> letter (a-z)</p>
                        <p id="pass-upper" className="invalid fs-10 mb-1">At least <b>uppercase</b> letter (A-Z)</p>
                        <p id="pass-number" className="invalid fs-10 mb-0">A least <b>number</b> (0-9)</p>
                    </div>
                    <div className="error"> {this.state.PasswordErr}</div>

                    <div className='inputbox'>
                      <label for="" style={{ marginBottom: '10px' }} >Confirm Password<span class="mandatory">*</span></label>
                      <Input id="confirmpassword" placeholder="Confirm Password" class="input" tabindex='5'
                        type="password"
                        value={this.state.confirmpassword}
                        onChange={(e) => {

                          this.setState({ confirmpassword: e.target.value })
                        }
                        }
                        onKeyUp={(e) => { this.passmatchfunc(); }}
                      />

                    </div>
                    <div className="error"> {this.state.confirmpasswordErr}</div>

                    <div className='tc'>
                      <chbox> <input type="checkbox" tabindex='6' style={{ width: '20px', height: '20px' }} id="agree" onChange={this.checkboxHandler} /></chbox>
                      <label> I agree to <a href='https://www.cetas.medtech-gurus.comterms-of-use/' target='_blank'><b>terms and conditions</b></a></label>
                      <error >{this.state.termconditionErr}</error>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                      <button type="submit" tabindex='7' onClick={this.UserSignupfun.bind(this)}>
                        Sign Up
                      </button>
                    </div>
                    {/* </form>
                            </div> */}
                    <div style={{ visibility: 'hidden', height: '1px' }}>
                      <h5>Create account with</h5>
                      <div className='inputboxsocial'>
                        <a href="#" target="_blank"><i className='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i className='bx bxl-google'></i></a>
                        <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>

                        <a href="#" target="_blank"><i className='bx bxl-twitter'></i></a>

                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </section>

          {/* <footer>
        <div className="footer-top text-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                        {/* <div className="col-auto social-icons">
                            <a href="https://www.linkedin.com/company/cetas-healthcare/" target="_blank"><i className='bx bxl-linkedin'></i></a>
                            <a href="https://twitter.com/cetashealthcare" target="_blank"><i className='bx bxl-twitter'></i></a>
                            <a href="https://www.youtube.com/channel/UCzJPjYnPXRZjWs-H1IqvMFQ" target="_blank"><i className='bx bxl-youtube'></i></a>
                        </div>
                        <div className="col-auto conditions-section">
                            <a href="https://www.cetas.medtech-gurus.comprivacy_policy/" target="_blank" rel="opener">Privacy</a>
                            <a href="https://www.cetas.medtech-gurus.comterms-of-use/" target="_blank" rel="opener">Terms</a>
                            <a href="https://www.cetas.medtech-gurus.comcookie-notice/" target="_blank" rel="opener">Cookie Notice</a>
                        </div> 
                        <span className="mb-0">© 2023 Cetas Healthcare. - ISO 9001 Certified</span>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}

        </div>











        {/* </header> */}

      </body>
    );
  }
}

export default Signupinfo;