import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { displayName } from 'react-widgets/lib/Calendar';

// var _seed = 42;
// Math.random = function () {
//   _seed = _seed * 16807 % 2147483647;
//   return (_seed - 1) / 2147483646;
// };

class ApexChart extends Component {
  constructor(props) {
    super(props);
    console.log('props of apex chart', this.props.Data);
  
    this.state = {
      series: [
        
        {
          name:'Lifetime',
          color:'#035B96',
          type: 'bar',  
          
          data: [
            19,22,33,23,42,
            // this.props.Data.kol_guidlines.count_last_year,
            // this.props.Data.kol_clinical_trial_details.count_last_year,
            // this.props.Data.kol_publication_details.count_last_year,
            // this.props.Data.kol_advisory_board.count_last_year,
            // this.props.Data.kol_event_details.count_last_year,
          ]
        },
        
        {
        name: this.props.Data.year.last_year,
        color:'#A55B52',
        type: 'bar',
         
        data: [
          9,12,23,43,22,
          // this.props.Data.kol_guidlines.count_last_year,
          // this.props.Data.kol_clinical_trial_details.count_last_year,
          // this.props.Data.kol_publication_details.count_last_year,
          // this.props.Data.kol_advisory_board.count_last_year,
          // this.props.Data.kol_event_details.count_last_year,
        ]
      },
      
      {
        name: this.props.Data.year.mid_year,
        type: 'bar',
        //color:'#72DADD',
        data: [
          10,20,30,35,40,
          // this.props.Data.kol_guidlines.count_mid_year,
          // this.props.Data.kol_clinical_trial_details.count_mid_year,
          // this.props.Data.kol_publication_details.count_mid_year,
          // this.props.Data.kol_advisory_board.count_mid_year,
          // this.props.Data.kol_event_details.count_mid_year,
        ]
      },
       {
        name: this.props.Data.year.latest_year,
        type: 'bar',
        //color:'#D7B4B0',
        data: [
          10,5,15,28,38,
          // this.props.Data.kol_guidlines.count_latest_year,
          // this.props.Data.kol_clinical_trial_details.count_latest_year,
          // this.props.Data.kol_publication_details.count_latest_year,
          // this.props.Data.kol_advisory_board.count_latest_year,
          // this.props.Data.kol_event_details.count_latest_year
        ]
      }, 
      // {
      //   name: 'Growth',
      //   type: 'line',
      //   data: [
      //     this.props.Data.Growth.kol_guidelines,
      //     this.props.Data.Growth.kol_clinical_trial_details,
      //     this.props.Data.Growth.kol_publication_details,
      //     this.props.Data.Growth.kol_advisory_board,
      //     this.props.Data.Growth.kol_events_details
      //   ]
      // }
    
    ],
      options: {
        chart: {
          type: 'bar',
          stacked: false,
          
        //   sparkline: {
        //     enabled: false
        //  }

        

        },
       

        dataLabels: { 
          enabled: true,
          offsetY: -10,         
          style: {          
             fontSize: '12px',
             //fontFamily: 'Helvetica, Arial, sans-serif',
             fontWeight: 'normal',
             colors: ['#000', '#000', '#000', '#000'],
             //colors: undefined
         },

        //  background: {
        //   enabled: false,
        //   foreColor: '#000',
        //   padding: 8,
        //   borderRadius: 2,
        //   borderWidth: 1,
        //   borderColor: '#000',          
        //   opacity: 0.9,
        //    dropShadow: {
        //      enabled: true,
        //      top: 1,
        //     left: 1,
        //     blur: 1,
        //     color: '#fff',
        //     opacity: 0.45
        //    }
        // },
       
      },
    
        
        stroke: {
            show: true,
            width:10,
            colors: ["transparent"],
           
        },
        title: {
          text: 'Engagement Summary',
          align: 'left',
          height: 13,
         // offsetX: 110
        },
        // xaxis: {
        //  // categories: ['Clinical Guidelines', 'Investigations', 'Publications', 'Ad Boards', 'Speakerships'],
        //   //offset: 100,
         
        // },


        grid: {
          show: false,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
             }
           },  
          yaxis: {
            lines: { 
              show: false  //or just here to disable only y axis
             }
           },   
        },

        
        plotOptions: {
          bar: {
           // horizontal: false,
            columnWidth: "65%",            
           // endingShape: "rounded"

            
    
          }
        },

        yaxis: [      

          // {            
          //   axisTicks: {
          //     show: true,
          //     min: 0,
          //     max: 100,
          //     tickAmount: 20
          //   },
          //   axisBorder: {
          //    show: true,
          //     color: '#252525'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#000000',
          //     }
          //   },
          //   title: {
          //    text: "Counts",
          //     style: {
          //       color: '#252525',
          //     }
          //   },
          //   tooltip: {
          //     enabled: true
          //   }
          // },

          {
            seriesName:'Lifetime',            
            //opposite: true,
            
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              // color: '#00E396'
            },
            labels: {
              show: false,
            },
            title: {
              // text: "2019",
              style: {
                color: '#00E396',
              }
            },
            
          },
          {
            seriesName: this.props.Data.year.last_year,
            
            //opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              // color: '#00E396'
            },
            labels: {
              show: false,
            },
            title: {
              // text: "2019",
              style: {
                color: '#00E396',
              }
            },
          },
          {
            // seriesName: '2020',
           // color:'red',
            //opposite: true,
           // width:'20%',
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              //color: '#00E396'
            },
            labels: {
              show: false,
            },
            title: {
              // text: "2020",
              style: {
                color: '#000',
              }
            },
           }, 

           

           //{
          //   seriesName: 'Growth',
          //   opposite: true,
          //   axisTicks: {
          //     show: true,
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#ff536c'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#ff536c',
          //     },
          //   },
          //   title: {
          //     text: "Growth",
          //     style: {
          //       color: '#ff536c',
          //     }
          //   }
          // },
        ],
        // tooltip: {
        //   followCursor: true,
        //   enabled: true,
        //   // fixed: {
        //   //   enabled: true,
        //   //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        //   //   offsetY: 30,
        //   //   offsetX: 60
        //   // },
        // },
        // legend: {
        //   horizontalAlign: 'center',
        //   //offsetX: 40

          
        // }
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart" >
          {/* <Chart options={this.state.options}  series={this.state.series} type="line"  /> */}
          
          <Chart options={this.state.options}  series={this.state.series}  type="bar"  />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}
export default ApexChart;