import React, { Component, useRef } from 'react';
import { Redirect, Link ,NavLink} from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import PhoneInput from 'react-phone-input-2';
import { Multiselect } from 'react-widgets';
//import Spinner from '../Assets/Spinner'
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import { isEmptyArray } from 'formik';



export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}


class pdftest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowBar: false,
      alert: null,
      redirect: false,
     
     
    };



  }

  
  componentDidMount = async () => {
    console.log('in componentDidMount');
    
    
  }
  
  exporttopdf()
  {
    alert('hi')
  }

 
  
  render() {

  // if (this.state.redirect) {
  //           return (<Redirect to={'/'}/>)
  // }

  if (this.state.redirect) {
    return (<Redirect to={'/home'} />)
}

  if (this.state.redirecthome) {
    return (<Redirect to={'/home'} />)
  }

if (this.state.redirectLogin) {
  return (<Redirect to={'/login'}/>)
}

if (this.state.redirectSignup) {
  return (<Redirect to={'/signup'}/>)
}



    if (this.state.redirectPClientViewKolList) {
        return (<Redirect to={'/PClientViewKolList'}/>)
    }

    if (this.state.redirectAdmin) {
      return (<Redirect to={'/AdminDashboard'} />)
    }

    if (this.state.redirectResercher) {
      return (<Redirect to={{
        pathname: '/ResercherDashboard',
        state: { RID: this.state.RID, resercher_name: this.state.resercher_name }
      }} />)
    }

    if (this.state.redirectManager) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name} 
      }} />)
    }

    if (this.state.redirectEmployee) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
        //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name} 
      }} />)
    }

    if (this.state.redirectClientDashboard) {
      return (<Redirect to={{
        pathname: '/ClientViewProject',
      }} />)
    }

    // if (this.state.redirectPClientViewKolList) {
    //   return (<Redirect to={{
    //     pathname: '/ClientViewKolList',
    //   }} />)
    // }


    if (this.state.redirectAdministratorDashboard) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }

    if (this.state.redirectVisitorDashboard) {
      return (<Redirect to={{
        pathname: '/VisitorDashboard',
      }} />)
    }

    if (this.state.redirectKolProfile) {
      return (<Redirect to={{
        pathname: '/KolProfileDashboard',
      }} />)
    }

    if (this.state.redirectTermsandPrivacy) {
      return (<Redirect to={{
        pathname: '/TermsPrivacyPolicy',
      }} />)
    }

    


    return (

        
      <body id="landing-body">


        <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}
        
         {/* {AddLibrary('./js/app.js')} */}
  

        <h2>Test pdf convert</h2>


   
        <button onClick={this.exporttopdf}>Export to pdf</button>
          



      </body>
    );
  }
}

export default pdftest;